import actions from './actions';

const {
  GET_RATING_BEGIN, GET_RATING_SUCCESS, GET_RATING_FAILED,
  DELETE_RATING_BEGIN, DELETE_RATING_SUCCESS, DELETE_RATING_FAILED
} = actions;

const initState = {
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  ratings: [],
  loading: false,
  error: null,
};


const RATINGReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_RATING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_RATING_SUCCESS:
      return {
        ...state,
        total: data.total,
        ratings: data.ratings,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: false,
      };
    case GET_RATING_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_RATING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_RATING_SUCCESS:
      return {
        ...state,
        total: state.total - 1,
        ratings: data,
        loading: false,
      };
    case DELETE_RATING_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default RATINGReducer;