import actions from './actions';

const {
  GET_VARIANT_BEGIN,
  GET_VARIANT_SUCCESS,
  GET_VARIANT_FAILED,
  GET_VARIANT_BY_MODEL_BEGIN,
  GET_VARIANT_BY_MODEL_SUCCESS,
  GET_VARIANT_BY_MODEL_FAILED,
  ADD_VARIANT_BEGIN,
  ADD_VARIANT_SUCCESS,
  ADD_VARIANT_FAILED,
  UPDATE_VARIANT_BEGIN,
  UPDATE_VARIANT_SUCCESS,
  UPDATE_VARIANT_FAILED,
  DELETE_VARIANT_BEGIN,
  DELETE_VARIANT_SUCCESS,
  DELETE_VARIANT_FAILED,
  GET_ONE_VARIANT_BEGIN,
  GET_ONE_VARIANT_SUCCESS,
  GET_ONE_VARIANT_FAILED,
  GET_BASE_VARIANT_BEGIN,
  GET_BASE_VARIANT_SUCCESS,
  GET_BASE_VARIANT_FAILED,
} = actions;

const initState = {
  variants: [],
  byModels: [],
  baseVariant: [],
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  loading: false,
  error: null,
};

const VariantReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_VARIANT_BEGIN:
      return {
        ...state,
        loading: true,
        variants: [],
        total: 0,
      };
    case GET_VARIANT_SUCCESS:
      return {
        ...state,
        variants: data.vehicles,
        total: data.total || 0,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: data.currentPage ? false : true,
      };
    case GET_VARIANT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_VARIANT_BY_MODEL_BEGIN:
      return {
        ...state,
        loading: true,
        byModles: [],
      };
    case GET_VARIANT_BY_MODEL_SUCCESS:
      return {
        ...state,
        byModels: data.vehicles,
        loading: false,
      };
    case GET_VARIANT_BY_MODEL_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_VARIANT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_VARIANT_SUCCESS:
      return {
        ...state,
        variants: data,
        total: state.total + 1,
        loading: false,
      };
    case ADD_VARIANT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_VARIANT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_VARIANT_SUCCESS:
      return {
        ...state,
        // variants: data,
        loading: false,
      };
    case UPDATE_VARIANT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_VARIANT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_VARIANT_SUCCESS:
      return {
        ...state,
        variants: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_VARIANT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_ONE_VARIANT_BEGIN:
      return {
        ...state,
        loading: true,
        variants: [],
        total: 0,
      };
    case GET_ONE_VARIANT_SUCCESS:
      return {
        ...state,
        variants: data?.[0],
        loading: false,
      };
    case GET_ONE_VARIANT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case GET_BASE_VARIANT_BEGIN:
      return {
        ...state,
        loading: true,
        baseVariant: [],
      };
    case GET_BASE_VARIANT_SUCCESS:
      return {
        ...state,
        baseVariant: data,
        loading: false,
      };
    case GET_BASE_VARIANT_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default VariantReducer;
