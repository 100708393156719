import actions from './actions';

const {
  GET_AD_BEGIN, GET_AD_SUCCESS, GET_AD_FAILED,
  ADD_AD_BEGIN, ADD_AD_SUCCESS, ADD_AD_FAILED,
  UPDATE_AD_BEGIN, UPDATE_AD_SUCCESS, UPDATE_AD_FAILED,
  DELETE_AD_BEGIN, DELETE_AD_SUCCESS, DELETE_AD_FAILED
} = actions;

const initState = {
  ads: [],
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  loading: false,
  error: null
};

const AdReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_AD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_AD_SUCCESS:
      return {
        ...state,
        ads: data.ads,
        total: data.total || 0,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: data.currentPage ? false : true,
      };
    case GET_AD_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_AD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_AD_SUCCESS:
      return {
        ...state,
        ads: data,
        total: state.total + 1,
        loading: false,
      };
    case ADD_AD_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_AD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_AD_SUCCESS:
      return {
        ...state,
        ads: data,
        loading: false,
      };
    case UPDATE_AD_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_AD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_AD_SUCCESS:
      return {
        ...state,
        ads: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_AD_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default AdReducer;