const actions = {
  GET_BODY_TYPE_BEGIN: 'GET_BODY_TYPE_BEGIN',
  GET_BODY_TYPE_SUCCESS: 'GET_BODY_TYPE_SUCCESS',
  GET_BODY_TYPE_FAILED: 'GET_BODY_TYPE_FAILED',

  ADD_BODY_TYPE_BEGIN: 'ADD_BODY_TYPE_BEGIN',
  ADD_BODY_TYPE_SUCCESS: 'ADD_BODY_TYPE_SUCCESS',
  ADD_BODY_TYPE_FAILED: 'ADD_BODY_TYPE_FAILED',

  UPDATE_BODY_TYPE_BEGIN: 'UPDATE_BODY_TYPE_BEGIN',
  UPDATE_BODY_TYPE_SUCCESS: 'UPDATE_BODY_TYPE_SUCCESS',
  UPDATE_BODY_TYPE_FAILED: 'UPDATE_BODY_TYPE_FAILED',

  DELETE_BODY_TYPE_BEGIN: 'DELETE_BODY_TYPE_BEGIN',
  DELETE_BODY_TYPE_SUCCESS: 'DELETE_BODY_TYPE_SUCCESS',
  DELETE_BODY_TYPE_FAILED: 'DELETE_BODY_TYPE_FAILED',

  getBodyTypeBegin: () => {
    return {
      type: actions.GET_BODY_TYPE_BEGIN,
    };
  },

  getBodyTypeSuccess: data => {
    return {
      type: actions.GET_BODY_TYPE_SUCCESS,
      data,
    };
  },

  getBodyTypeFailed: err => {
    return {
      type: actions.GET_BODY_TYPE_FAILED,
      err,
    };
  },

  addBodyTypeBegin: () => {
    return {
      type: actions.ADD_BODY_TYPE_BEGIN,
    };
  },

  addBodyTypeSuccess: data => {
    return {
      type: actions.ADD_BODY_TYPE_SUCCESS,
      data,
    };
  },

  addBodyTypeFailed: err => {
    return {
      type: actions.ADD_BODY_TYPE_FAILED,
      err,
    };
  },

  updateBodyTypeBegin: () => {
    return {
      type: actions.UPDATE_BODY_TYPE_BEGIN,
    };
  },

  updateBodyTypeSuccess: data => {
    return {
      type: actions.UPDATE_BODY_TYPE_SUCCESS,
      data,
    };
  },

  updateBodyTypeFailed: err => {
    return {
      type: actions.UPDATE_BODY_TYPE_FAILED,
      err,
    };
  },

  deleteBodyTypeBegin: () => {
    return {
      type: actions.DELETE_BODY_TYPE_BEGIN,
    };
  },

  deleteBodyTypeSuccess: data => {
    return {
      type: actions.DELETE_BODY_TYPE_SUCCESS,
      data,
    };
  },

  deleteBodyTypeFailed: err => {
    return {
      type: actions.DELETE_BODY_TYPE_FAILED,
      err,
    };
  },
};

export default actions;
