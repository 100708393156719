const actions = {
  GET_DESCRIPTION_BEGIN: 'GET_DESCRIPTION_BEGIN',
  GET_DESCRIPTION_SUCCESS: 'GET_DESCRIPTION_SUCCESS',
  GET_DESCRIPTION_FAILED: 'GET_DESCRIPTION_FAILED',

  ADD_DESCRIPTION_BEGIN: 'ADD_DESCRIPTION_BEGIN',
  ADD_DESCRIPTION_SUCCESS: 'ADD_DESCRIPTION_SUCCESS',
  ADD_DESCRIPTION_FAILED: 'ADD_DESCRIPTION_FAILED',

  UPDATE_DESCRIPTION_BEGIN: 'UPDATE_DESCRIPTION_BEGIN',
  UPDATE_DESCRIPTION_SUCCESS: 'UPDATE_DESCRIPTION_SUCCESS',
  UPDATE_DESCRIPTION_FAILED: 'UPDATE_DESCRIPTION_FAILED',

  DELETE_DESCRIPTION_BEGIN: 'DELETE_DESCRIPTION_BEGIN',
  DELETE_DESCRIPTION_SUCCESS: 'DELETE_DESCRIPTION_SUCCESS',
  DELETE_DESCRIPTION_FAILED: 'DELETE_DESCRIPTION_FAILED',

  getDescriptionBegin: () => {
    return {
      type: actions.GET_DESCRIPTION_BEGIN,
    };
  },

  getDescriptionSuccess: data => {
    return {
      type: actions.GET_DESCRIPTION_SUCCESS,
      data,
    };
  },

  getDescriptionFailed: err => {
    return {
      type: actions.GET_DESCRIPTION_FAILED,
      err,
    };
  },

  addDescriptionBegin: () => {
    return {
      type: actions.ADD_DESCRIPTION_BEGIN,
    };
  },

  addDescriptionSuccess: data => {
    return {
      type: actions.ADD_DESCRIPTION_SUCCESS,
      data,
    };
  },

  addDescriptionFailed: err => {
    return {
      type: actions.ADD_DESCRIPTION_FAILED,
      err,
    };
  },

  updateDescriptionBegin: () => {
    return {
      type: actions.UPDATE_DESCRIPTION_BEGIN,
    };
  },

  updateDescriptionSuccess: data => {
    return {
      type: actions.UPDATE_DESCRIPTION_SUCCESS,
      data,
    };
  },

  updateDescriptionFailed: err => {
    return {
      type: actions.UPDATE_DESCRIPTION_FAILED,
      err,
    };
  },
  deleteDescriptionBegin: () => {
    return {
      type: actions.DELETE_DESCRIPTION_BEGIN,
    };
  },

  deleteDescriptionSuccess: data => {
    return {
      type: actions.DELETE_DESCRIPTION_SUCCESS,
      data,
    };
  },

  deleteDescriptionFailed: err => {
    return {
      type: actions.DELETE_DESCRIPTION_FAILED,
      err,
    };
  },
};

export default actions;
