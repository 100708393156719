// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';
import Cookies from 'js-cookie';
import moment from 'moment';

// USER COMPONENTS
import { getAllBlogs } from '../../../redux/blog/actionCreator';
import { IMAGE_URL } from '../../../utils/constants';

const DisplayBlog = ({ match }) => {
  const dataIndex = (Cookies.get('lang') == 'np' ? 0 : 1);
  const dispatch = useDispatch();
  const [blog, setBlog] = useState({});

  const { blogs, loading } = useSelector(state => {
    return {
      blogs: state.blog.blogs,
      loading: state.blog.loading
    }
  });

  useEffect(() => {
    if (blogs.length === 0) dispatch(getAllBlogs());
    setBlog(blogs.find(blog => blog._id === match.params.id) || {});

    window.scrollTo(0, 0);
  }, [blogs.length]);

  // Receives an HTML string as an argument and returns an object with the sanitized HTML
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }

  if (Object.keys(blog).length > 0) {
    const { details, category, author, brand, image, featured, createdAt } = blog;

    return (
      <div style={{ padding: 20, backgroundColor: '#fff' }}>
        <h2 as={'h4'} style={{ textAlign: 'center', fontSize: 30, padding: 10, fontWeight: 'bold' }}>{details[dataIndex]?.title ?? 'NA'}</h2>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={`${IMAGE_URL}/${image}`} alt="blog_image" style={{ textAlign: 'center', width: 200 }} />
        </div>
        <br />
        <div className="preview" dangerouslySetInnerHTML={createMarkup(details[dataIndex]?.description) ?? 'NA'}></div>
        <br />
        <div className="info" style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ marginBottom: 0 }}> <span style={{ fontWeight: 'bold' }}>Author:</span> {author?.name}</p>
          <p style={{ marginBottom: 0 }}> <span style={{ fontWeight: 'bold' }}>Category:</span> {category?.details[dataIndex]?.category ?? 'NA'}</p>
        </div>
        <div className="info" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ marginBottom: 0 }}> <span style={{ fontWeight: 'bold' }}>Is Featured:</span> {featured ? 'Yes' : 'No'}</p>
          <p style={{ marginBottom: 0 }}> <span style={{ fontWeight: 'bold' }}>CreatedAt:</span> {moment(createdAt).format("ddd D MMM YYYY")}</p>
        </div>
        <div className="info" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
          <p style={{ marginBottom: 0 }}> <span style={{ fontWeight: 'bold' }}>Brand:</span> {brand ? brand?.brand : 'NA'}</p>
        </div>
      </div>
    );
  }
  return loading ? (
    <div className="spin">
      <Spin />
    </div>
  ) : <h4 style={{ fontSize: 30, height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Blog Not Found</h4>;
};

export default DisplayBlog;