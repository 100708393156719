const actions = {
  GET_AD_BEGIN: 'GET_AD_BEGIN',
  GET_AD_SUCCESS: 'GET_AD_SUCCESS',
  GET_AD_FAILED: 'GET_AD_FAILED',

  ADD_AD_BEGIN: 'ADD_AD_BEGIN',
  ADD_AD_SUCCESS: 'ADD_AD_SUCCESS',
  ADD_AD_FAILED: 'ADD_AD_FAILED',

  UPDATE_AD_BEGIN: 'UPDATE_AD_BEGIN',
  UPDATE_AD_SUCCESS: 'UPDATE_AD_SUCCESS',
  UPDATE_AD_FAILED: 'UPDATE_AD_FAILED',

  DELETE_AD_BEGIN: 'DELETE_AD_BEGIN',
  DELETE_AD_SUCCESS: 'DELETE_AD_SUCCESS',
  DELETE_AD_FAILED: 'DELETE_AD_FAILED',

  getAdBegin: () => {
    return {
      type: actions.GET_AD_BEGIN,
    };
  },

  getAdSuccess: data => {
    return {
      type: actions.GET_AD_SUCCESS,
      data,
    };
  },

  getAdFailed: err => {
    return {
      type: actions.GET_AD_FAILED,
      err,
    };
  },

  addAdBegin: () => {
    return {
      type: actions.ADD_AD_BEGIN,
    };
  },

  addAdSuccess: data => {
    return {
      type: actions.ADD_AD_SUCCESS,
      data,
    };
  },

  addAdFailed: err => {
    return {
      type: actions.ADD_AD_FAILED,
      err,
    };
  },
  updateAdBegin: () => {
    return {
      type: actions.UPDATE_AD_BEGIN,
    };
  },

  updateAdSuccess: data => {
    return {
      type: actions.UPDATE_AD_SUCCESS,
      data,
    };
  },

  updateAdFailed: err => {
    return {
      type: actions.UPDATE_AD_FAILED,
      err,
    };
  },
  deleteAdBegin: () => {
    return {
      type: actions.DELETE_AD_BEGIN,
    };
  },

  deleteAdSuccess: data => {
    return {
      type: actions.DELETE_AD_SUCCESS,
      data,
    };
  },

  deleteAdFailed: err => {
    return {
      type: actions.DELETE_AD_FAILED,
      err,
    };
  },
};

export default actions;
