const actions = {
  GET_CALLBACK_BEGIN: 'GET_CALLBACK_BEGIN',
  GET_CALLBACK_SUCCESS: 'GET_CALLBACK_SUCCESS',
  GET_CALLBACK_FAILED: 'GET_CALLBACK_FAILED',

  UPDATE_CALLBACK_BEGIN: 'UPDATE_CALLBACK_BEGIN',
  UPDATE_CALLBACK_SUCCESS: 'UPDATE_CALLBACK_SUCCESS',
  UPDATE_CALLBACK_FAILED: 'UPDATE_CALLBACK_FAILED',

  DELETE_CALLBACK_BEGIN: 'DELETE_CALLBACK_BEGIN',
  DELETE_CALLBACK_SUCCESS: 'DELETE_CALLBACK_SUCCESS',
  DELETE_CALLBACK_FAILED: 'DELETE_CALLBACK_FAILED',

  getCallbackBegin: () => {
    return {
      type: actions.GET_CALLBACK_BEGIN,
    };
  },

  getCallbackSuccess: data => {
    return {
      type: actions.GET_CALLBACK_SUCCESS,
      data,
    };
  },

  getCallbackFailed: err => {
    return {
      type: actions.GET_CALLBACK_FAILED,
      err,
    };
  },

  updateCallbackBegin: () => {
    return {
      type: actions.UPDATE_CALLBACK_BEGIN,
    };
  },

  updateCallbackSuccess: data => {
    return {
      type: actions.UPDATE_CALLBACK_SUCCESS,
      data,
    };
  },

  updateCallbackFailed: err => {
    return {
      type: actions.UPDATE_CALLBACK_FAILED,
      err,
    };
  },

  deleteCallbackBegin: () => {
    return {
      type: actions.DELETE_CALLBACK_BEGIN,
    };
  },

  deleteCallbackSuccess: data => {
    return {
      type: actions.DELETE_CALLBACK_SUCCESS,
      data,
    };
  },

  deleteCallbackFailed: err => {
    return {
      type: actions.DELETE_CALLBACK_FAILED,
      err,
    };
  },
};

export default actions;