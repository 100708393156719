// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { Form, Input, Row, Col, Select, Spin, Switch } from 'antd';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import { useSelector, useDispatch } from 'react-redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//USER COMPONENTS
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import '../Blog.css';
import { Main } from '../../styled';
import { getAllUsers } from '../../../redux/users/actionCreator';
import { editBlog, getSingleBlog } from '../../../redux/blog/actionCreator';
import { getAllBlogCategories } from '../../../redux/blogcategory/actionCreator';
import { Button } from '../../../components/buttons/buttons';
import { BasicFormWrapper } from '../../styled';
import { getAllBrands } from '../../../redux/brand/actionCreator';

const EditBlog = ({ match }) => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const history = useHistory();
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();

  const { blogs, categories, users, loading, brands } = useSelector(state => {
    return {
      brands: state.brand.brands,
      blogs: state.blog.blogs,
      loading: state.blog.loading,
      categories: state.blogCategory.categories,
      users: state.user.users,
    }
  });

  // Handle on change switch
  const onChange = (checked) => {
    setEngInpu(checked);
  };

  // text editor state
  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML('')
      )
    )
  );

  const [editorStateEng, setEditorStateEng] = useState(
    () => EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML('')
      )
    )
  );

  const [convertedContent, setConvertedContent] = useState(null);
  const [convertedContentEng, setConvertedContentEng] = useState(null);

  useEffect(() => {
    if (users.length === 0) dispatch(getAllUsers());
    if (categories.length === 0) getAllBlogCategories(dispatch);
    if (blogs.length === 0) dispatch(getSingleBlog(match.params.id));

  }, []);

  const blog = blogs.find(blog => blog._id === match.params.id) || {};
  const [engInput, setEngInpu] = useState((blog.details) ? (blog?.details?.length > 1 ? true : false) : true);

  useEffect(() => {
    if (blog?.details?.length > 0) {
      dispatch(getAllBrands('?all=true'));

      ref1.current.focusEditor();
      setConvertedContent(blog.details[0].description);

      if (blog.details[1]) {
        setEngInpu(true);
        ref2?.current?.focusEditor();
        setConvertedContent(blog.details[1].description);
      }
    }
  }, [blog]);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  }

  const handleEditorChangeEng = (state) => {
    setEditorStateEng(state);
    convertContentToHTML('eng');
  }

  const convertContentToHTML = (lang = 'np') => {
    if (lang === 'np') {
      let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(currentContentAsHTML);
    }
    else {
      let currentContentAsHTML = convertToHTML(editorStateEng.getCurrentContent());
      setConvertedContentEng(currentContentAsHTML);
    }
  }

  // Handle submitting the form
  const handleSubmit = (datas) => {
    const formData = new FormData();
    if (image) formData.append('image', image);

    const { title, titleEng, featured, category, author, brand } = datas;
    const details = [{ lang: 'np', title, description: convertedContent }];
    if (engInput) details.push({ lang: 'en', title: titleEng, description: convertedContentEng });

    formData.append('details', JSON.stringify(details));
    if (featured) formData.append('featured', featured);
    if (category) formData.append('category', category);
    if (author) formData.append('author', author);
    if (brand) formData.append('brand', brand);

    dispatch(editBlog(blog._id, formData, history));
  }

  return loading ? (
    <div className="spin">
      <Spin />
    </div>
  ) : (
    <>
      <PageHeader
        ghost
        title={`Edit Blog`}
      />
      <Main>
        <Cards headless>
          {
            Object.keys(blog).length > 0 ? (
              <Row gutter={15}>
                <Col xs={1}></Col>
                <Col xs={22}>
                  <div className="edit-blog">
                    <BasicFormWrapper>
                      <Form
                        name="basic"
                        initialValues={{
                          ...blog,
                          title: blog.details[0].title,
                          titleEng: blog.details?.[1]?.title,
                          author: blog?.author?._id ?? '',
                          category: blog?.category?._id ?? '',
                          brand: blog?.brand?._id,
                          image: ''
                        }}
                        onFinish={handleSubmit}
                        autoComplete="off"
                      >
                        <BasicFormWrapper>
                          {/* language switcher */}
                          <div className="add-blog">
                            <Row>
                              <Col xs={24}>
                                <h4>Translate</h4>
                                <Switch defaultChecked={engInput} onChange={onChange} />
                              </Col>
                            </Row>
                          </div>
                          <br />
                          <BasicFormWrapper>
                            {/* title */}
                            <div className="add-blog">
                              <Row>
                                <Col xs={24}>
                                  <Form.Item
                                    name="title"
                                    label="Title"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input title!',
                                      },
                                    ]}
                                  >
                                    <Input placeholder='title ......' />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                            <br />
                            {
                              engInput && (
                                <>
                                  {/* title */}
                                  <div className="add-blog">
                                    <Row>
                                      <Col xs={24}>
                                        <Form.Item
                                          name="titleEng"
                                          label="Title (Eng)"
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Please input title!',
                                            },
                                          ]}
                                        >
                                          <Input placeholder='title ......' />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </div>
                                  <br />
                                </>
                              )
                            }
                            {/* description */}
                            <div className="add-blog">
                              <Row>
                                <Col xs={24}>
                                  <Form.Item
                                    name='description'
                                    label="Description"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input blog description!',
                                      },
                                    ]}
                                  >
                                    <Editor
                                      toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'] }}
                                      ref={ref1}
                                      defaultEditorState={EditorState.createWithContent(
                                        ContentState.createFromBlockArray(
                                          convertFromHTML(blog.details[0].description || "<p>Hello Kaka</p>")
                                        )
                                      )}
                                      onEditorStateChange={handleEditorChange}
                                      wrapperClassName="wrapper-class"
                                      editorClassName="editor-class"
                                      toolbarClassName="toolbar-class"
                                      autoComplete={true}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                            <br />
                            {
                              engInput && (
                                <>
                                  {/* description */}
                                  <div className="add-blog">
                                    <Row>
                                      <Col xs={24}>
                                        <Form.Item
                                          name="descriptionEng"
                                          label="Description (Eng)"
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Please input blog description!',
                                            },
                                          ]}
                                        >
                                          <Editor
                                            toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'] }}
                                            ref={ref2}
                                            defaultEditorState={EditorState.createWithContent(
                                              ContentState.createFromBlockArray(
                                                convertFromHTML(blog.details?.[1]?.description || '<p></p>')
                                              )
                                            )}
                                            onEditorStateChange={handleEditorChangeEng}
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </div>
                                  <br />
                                </>
                              )
                            }
                            {/* featured and is category */}
                            <div className="add-blog" >
                              <Row>
                                <Col xs={11} style={{ width: '100%' }}>
                                  <Form.Item
                                    name="featured"
                                    label="Is Blog Featured ?"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Blog Featured!',
                                      },
                                    ]}
                                  >
                                    <Select style={{ width: '100%' }} placeholder="Is blog featured">
                                      <Select.Option value={true}>Yes</Select.Option>
                                      <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col xs={1}></Col>
                                <Col xs={12} style={{ width: '100%' }}>
                                  <Form.Item
                                    name="category"
                                    label="Blog Category"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please select blog category!',
                                      },
                                    ]}
                                  >
                                    <Select style={{ width: '100%' }} placeholder="Choose Blog Category">
                                      {categories.map(category => <Select.Option value={category._id} key={category._id}>{category.details[0]?.category}</Select.Option>)}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                            <br />
                            {/* author and brand */}
                            <div className="add-blog">
                              <Row>
                                <Col xs={11}>
                                  <Form.Item name="author" label="Author">
                                    <Select placeholder="Choose author">
                                      {users.map(user => <Select.Option value={user._id} key={user._id}>{user.name}</Select.Option>)}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col xs={1}></Col>
                                <Col xs={12}>
                                  <Form.Item name="brand" label="Brand">
                                    <Select
                                      placeholder="Choose brand"
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                      }
                                    >
                                      {brands.map(brand => <Select.Option value={brand._id} key={brand._id}>{brand.brand}</Select.Option>)}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                            <br />
                            {/* image  */}
                            <div className="add-blog">
                              <Row>
                                <Col xs={11}>
                                  <Form.Item
                                    name="image"
                                    label="Blog Main Image"
                                  >
                                    <Input type={'file'} onChange={(e) => setImage(e.target.files[0])} />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                            <br />
                            <br />
                            <Form.Item
                              wrapperCol={{
                                offset: 10,
                                span: 16,
                              }}
                            >
                              <Button style={{ backgroundColor: '#155BDC', color: '#fff' }} htmlType="submit">
                                Update Blog
                              </Button>
                            </Form.Item>
                          </BasicFormWrapper>
                        </BasicFormWrapper>
                      </Form>
                    </BasicFormWrapper>
                  </div>
                </Col>
                <Col xs={1}></Col>
              </Row>
            ) : null
          }
        </Cards>
      </Main>
    </>
  );


};

export default EditBlog;