// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import actions from './actions';
import axios, { CancelToken } from '../axios';
import { message } from 'antd';
import Cookies from 'js-cookie';

// USER COMPONENTS
import store from '../store';
import errorHandling from '../error';

const {
  getBrandBegin,
  getBrandSuccess,
  getBrandFailed,
  addBrandBegin,
  addBrandSuccess,
  addBrandFailed,
  getVehicleBrandBegin,
  getVehicleBrandFailed,
  getVehicleBrandSuccess,
  updateBrandBegin,
  updateBrandSuccess,
  updateBrandFailed,
  deleteBrandBegin,
  deleteBrandSuccess,
  deleteBrandFailed,
} = actions;

// GETTING ALL  BRANDS
let cancelToken;
export const getAllBrands = filter => async dispatch => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getBrandSuccess({ brands: [] }));
    dispatch(getBrandBegin());
    const res = await axios.get(`brands${filter ? filter : ''}`, { cancelToken: cancelToken?.token });
    const { brands, total, currentPage, pageLimit } = res.data;

    dispatch(getBrandSuccess({ brands, total, currentPage, pageLimit }));
  } catch (err) {
    dispatch(getBrandFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
  }
};

// GETTING ALL VEHICLES BRANDS
export const getVehiclesBrands = () => async dispatch => {
  try {
    dispatch(getVehicleBrandBegin());
    const res = await axios.get('/brands/by-vehicle', {
      headers: { Authorization: `Bearer ${Cookies.get('authToken')}` },
    });
    dispatch(getVehicleBrandSuccess(res.data.vehicleBrands));
  } catch (err) {
    dispatch(getVehicleBrandFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
  }
};

// ADDING NEW  BRAND
export const addNewBrand = (datas, form, setEditorState, setEditorStateEn, setFileList) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(addBrandBegin());
    const res = await axios.post('brands', datas, {
      headers: { Authorization: `Bearer ${Cookies.get('authToken')}` },
    });
    const newBrands = [res.data.brand, ...state.brand.brands];

    dispatch(addBrandSuccess(newBrands));
    message.success('New vehicle brand created successfully');
    form.resetFields();
    setEditorState(null);
    setEditorStateEn(null);
    setFileList([]);
  } catch (err) {
    dispatch(addBrandFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};

// UPDATING BRAND BY ID
export const editBrand = (id, datas) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateBrandBegin());
    const res = await axios.put(`brands/${id}`, datas, {
      headers: { Authorization: `Bearer ${Cookies.get('authToken')}` },
    });
    const newBrand = state.brand.brands.map(brand => {
      if (brand._id === id) return res.data.brand;
      else return brand;
    });
    dispatch(updateBrandSuccess(newBrand));
    message.success('Vehicle brand updated successfully');
  } catch (err) {
    dispatch(updateBrandFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};

// DELETE BRAND
export const deleteBrand = id => async dispatch => {
  try {
    const state = store.getState();
    dispatch(deleteBrandBegin());
    await axios.delete(`brands/${id}`, { headers: { Authorization: `Bearer ${Cookies.get('authToken')}` } });

    dispatch(deleteBrandSuccess(state.brand.brands.filter(brand => brand._id !== id)));
    message.success('Vehicle brand deleted successfully');
  } catch (err) {
    dispatch(deleteBrandFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};
