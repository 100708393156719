import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
// import Notification from './notification';
import { Popover } from '../../popup/popup';
import { Dropdown } from '../../dropdown/dropdown';
import { checkUserAuthenticated, logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import Cookies from 'js-cookie';
import { IMAGE_URL } from '../../../utils/constants';

const AuthInfo = () => {
  const [state, setState] = useState({ flag: Cookies.get('lang') ?? "en" });
  const path = window.location.pathname;
  const splitPath = path.split('/');
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(state => {
    return {
      user: state.auth.user,
    };
  });
  if (window.location.pathname !== '/admin')
    Cookies.set('currentPath', window.location.pathname);

  let profilePath = `${user.role.name}/profile`;
  if (splitPath.length === 3)
    profilePath = `../${user.role.name}/profile`;
  else if (splitPath.length === 4)
    profilePath = `../../${user.role.name}/profile`;
  else if (splitPath.length === 5)
    profilePath = `../../../${user.role.name}/profile`;

  useEffect(() => {
    if (Object.keys(user).length === 0)
      dispatch(checkUserAuthenticated(history));
  }, []);


  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut());
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={user?.profile ? `${IMAGE_URL}/${user.profile}` : require('../../../static/img/avatar/chat-auth.png')} alt="profile" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
          <figcaption>
            <Heading as="h5">{user?.name}</Heading>
            <p>Admin</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to={profilePath}>
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = value => {
    setState({ ...state, flag: value, });
    Cookies.set('lang', value);
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle('en')} to="#">
        <img src={require('../../../static/img/flag/en.png')} alt="" width={10} height={10} />
        <span>English</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle('np')} to="#">
        <img src={require('../../../static/img/flag/np.png')} alt="" width={10} height={10} />
        <span>Nepali</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Message /> */}
      {/* <Notification /> */}

      {/* <Settings /> */}
      {/* <Support /> */}
      <div className="nav-author">
        <Dropdown placement="bottomRight" content={country} trigger="click">
          <Link to="#" className="head-example">
            <img src={require(`../../../static/img/flag/${state.flag}.png`)} alt="" />
          </Link>
        </Dropdown>
      </div>

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src={user?.profile ? `${IMAGE_URL}/${user.profile}` : require('../../../static/img/avatar/chat-auth.png')} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
