// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// USER COMPONENTS
import Pages from './pages';
import withAdminLayout from '../../layout/withAdminLayout';
const Dashboard = lazy(() => import('../../container/dashboard'));
const User = lazy(() => import('../../container/user/User'));
const DisplayUser = lazy(() => import('../../container/user/overview/DisplayUser'));
const ViewCompareVehicle = lazy(() => import('../../container/user/overview/ViewCompareVehicle'));

const Ad = lazy(() => import('../../container/ad/Ad'));
const Faq = lazy(() => import('../../container/faq/Faq'));

const Brand = lazy(() => import('../../container/brand/Brand'));
const Model = lazy(() => import('../../container/model/Model'));
const Variant = lazy(() => import('../../container/variant/Variant'));
const BodyType = lazy(() => import('../../container/bodytype/BodyType'));
const Color = lazy(() => import('../../container/color/Color'));
const Vehicle = lazy(() => import('../../container/vehicle/Vehicle'));
const VehicleDetail = lazy(() => import('../../container/vehicle/overview/VehicleDetail'));
const CreateVehicle = lazy(() => import('../../container/vehicle/overview/CreateVehicle'));
const EditVehicle = lazy(() => import('../../container/vehicle/overview/EditVehicle'));
const VehicleColor = lazy(() => import('../../container/vehicle color/VehicleColor'));
const CompareVehicle = lazy(() => import('../../container/compare vehicles/CompareVehicle'));
const Description = lazy(() => import('../../container/description/Description'));

const Blog = lazy(() => import('../../container/blog/Blog'));
const CreateBlog = lazy(() => import('../../container/blog/overview/CreateBlog'));
const EditBlog = lazy(() => import('../../container/blog/overview/EditBlog'));
const DisplayBlog = lazy(() => import('../../container/blog/overview/DisplayBlog'));
const BlogCategory = lazy(() => import('../../container/blogcategory/BlogCategory'));
const BlogVideo = lazy(() => import('../../container/blog video/BlogVideo'));

const ContactInformation = lazy(() => import('../../container/contact information/ContactInformation'));
const Review = lazy(() => import('../../container/review/Review'));
const Rating = lazy(() => import('../../container/rating/Rating'));
const ReviewRating = lazy(() => import('../../container/rating/Rating'));

const Profile = lazy(() => import('../../container/profile/myProfile/Index'));
const Subscriber = lazy(() => import('../../container/subscriber/Subscriber'));
const Myprofile = lazy(() => import('../../container/profile/myProfile/Index'));
const Slider = lazy(() => import('../../container/slider/Slider'));
const Callback = lazy(() => import('../../container/callback/Callback'));
const ContactQuery = lazy(() => import('../../container/contact query/ContactQuery'));
const Page = lazy(() => import('../../container/page/Page'));
const CreatePage = lazy(() => import('../../container/page/overview/CreatePage'));
const EditPage = lazy(() => import('../../container/page/overview/EditPage'));
const DisplayPage = lazy(() => import('../../container/page/overview/DisplayPage'));
const CreateVariant = lazy(() => import('../../container/variant/overview/CreateTrim'));
const EditVariant = lazy(() => import('../../container/variant/overview/EditVariant'));

const Admin = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path={path} component={Dashboard} />
        <Route exact path={`${path}/users`} component={User} />
        <Route exact path={`${path}/user/:id`} component={DisplayUser} />
        <Route exact path={`${path}/users/compare-vehicle`} component={ViewCompareVehicle} />
        <Route exact path={`${path}/profile`} component={Profile} />

        <Route path={`${path}/body-types`} component={BodyType} />
        <Route path={`${path}/models`} component={Model} />
        <Route path={`${path}/variants`} component={Variant} />
        <Route path={`${path}/brands`} component={Brand} />
        <Route path={`${path}/colors`} component={Color} />
        <Route path={`${path}/ads`} component={Ad} />
        <Route path={`${path}/contact-queries`} component={ContactQuery} />

        <Route exact path={`${path}/vehicles`} component={Vehicle} />
        <Route exact path={`${path}/vehicles/edit/:id`} component={EditVehicle} />
        <Route exact path={`${path}/vehicles/detail/:id`} component={VehicleDetail} />
        <Route exact path={`${path}/vehicles/add`} component={CreateVehicle} />
        <Route exact path={`${path}/vehicle-colors`} component={VehicleColor} />
        <Route exact path={`${path}/compare-vehicles`} component={CompareVehicle} />
        <Route exact path={`${path}/variant/add`} component={CreateVariant} />
        <Route exact path={`${path}/variant/edit/:id`} component={EditVariant} />

        <Route exact path={`${path}/faqs`} component={Faq} />

        <Route exact path={`${path}/blogs`} component={Blog} />
        <Route exact path={`${path}/blogs/add`} component={CreateBlog} />
        <Route exact path={`${path}/blogs/edit/:id`} component={EditBlog} />
        <Route exact path={`${path}/blogs/view/:id`} component={DisplayBlog} />
        <Route exact path={`${path}/blog-categories`} component={BlogCategory} />
        <Route exact path={`${path}/blog-videos`} component={BlogVideo} />

        <Route path={`${path}/subscribers`} component={Subscriber} />
        <Route path={`${path}/reviews`} component={Review} />
        <Route path={`${path}/review&ratings`} component={ReviewRating} />
        <Route path={`${path}/contact-information`} component={ContactInformation} />
        <Route path={`${path}/sliders`} component={Slider} />
        <Route path={`${path}/callbacks`} component={Callback} />

        <Route exact path={`${path}/descriptions`} component={Description} />
        <Route exact path={`${path}/pages`} component={Page} />
        <Route exact path={`${path}/pages/add`} component={CreatePage} />
        <Route exact path={`${path}/pages/edit/:id`} component={EditPage} />
        <Route exact path={`${path}/pages/display/:id`} component={DisplayPage} />

        <Route path={`${path}`} component={Pages} />
        <Route path={`${path}/profile/myProfile`} component={Myprofile} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin, 'admin');
