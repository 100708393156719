// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Select, Spin, Switch } from 'antd';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';

// USER COMPONENTS
import '../Blog.css';
import { Main } from '../../styled';
import { getAllUsers } from '../../../redux/users/actionCreator';
import { addNewBlog } from '../../../redux/blog/actionCreator';
import { getAllBlogCategories } from '../../../redux/blogcategory/actionCreator';
import { Button } from '../../../components/buttons/buttons';
import { BasicFormWrapper } from '../../styled';
import { getAllBrands } from '../../../redux/brand/actionCreator';

const AddBlog = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const dataIndex = Cookies.get('lang') === 'np' ? 0 : 1;
  const [image, setImage] = useState(null);
  const [engInput, setEngInpu] = useState(false);
  const dispatch = useDispatch();

  const { categories, users, loading, brands } = useSelector(state => {
    return {
      loading: state.blog.loading,
      brands: state.brand.brands,
      categories: state.blogCategory.categories,
      users: state.user.users,
    }
  });

  // text editor state
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty()
  );
  const [editorStateEng, setEditorStateEng] = useState(
    () => EditorState.createEmpty()
  );

  const [convertedContent, setConvertedContent] = useState(null);
  const [convertedContentEng, setConvertedContentEng] = useState(null);

  useEffect(() => {
    dispatch(getAllBrands('?all=true'));
    if (users.length === 0) dispatch(getAllUsers());
    if (categories.length === 0) getAllBlogCategories(dispatch);
  }, []);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  }

  const handleEditorChangeEng = (state) => {
    setEditorStateEng(state);
    convertContentToHTML('eng');
  }

  const convertContentToHTML = (lang = 'np') => {
    if (lang === 'np') {
      let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(currentContentAsHTML);
    }
    else {
      let currentContentAsHTML = convertToHTML(editorStateEng.getCurrentContent());
      setConvertedContentEng(currentContentAsHTML);
    }
  }

  // Handle submitting the form
  const handleSubmit = (datas) => {
    const formData = new FormData();
    if (image) formData.append('image', image);

    const { title, titleEng } = datas;
    const details = [{ lang: 'np', title, description: convertedContent }];
    if (engInput) details.push({ lang: 'en', title: titleEng, description: convertedContentEng });

    Object.keys(datas).map(key => {
      if (datas.isFeatured || datas.category || datas.brand)
        formData.append(key, datas[key]);
    })
    formData.append('details', JSON.stringify(details));
    dispatch(addNewBlog(formData, form, history));
  }

  const onChange = (checked) => {
    setEngInpu(checked);
  };

  return loading ? (
    <div className="spin">
      <Spin />
    </div>
  ) : (
    <>
      <PageHeader
        ghost
        title={`Add Blog`}
      />
      <Main>
        <Cards headless>
          <Row gutter={15}>
            <Col xs={1}></Col>
            <Col xs={22}>
              <div className="edit-blog">
                <BasicFormWrapper>
                  {/* language switcher */}
                  <div className="add-blog">
                    <Row>
                      <Col xs={24}>
                        <h4>Translate</h4>
                        <Switch onChange={onChange} />
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <Form
                    name="basic"
                    initialValues={{}}
                    onFinish={handleSubmit}
                    autoComplete="off"
                  >
                    <BasicFormWrapper>
                      {/* title */}
                      <div className="add-blog">
                        <Row>
                          <Col xs={24}>
                            <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please enter the nepali title!' }]}>
                              <Input placeholder='Title' />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <br />
                      {
                        engInput && (
                          <>
                            {/* title */}
                            <div className="add-blog">
                              <Row>
                                <Col xs={24}>
                                  <Form.Item
                                    name="titleEng"
                                    label="Title (Eng)"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input title!',
                                      },
                                    ]}
                                  >
                                    <Input placeholder='title ......' />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                            <br />
                          </>
                        )
                      }
                      {/* description */}
                      <div className="add-blog">
                        <Row>
                          <Col xs={24}>
                            <Form.Item
                              name="description"
                              label="Description"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input blog description!',
                                },
                              ]}
                            >
                              <Editor
                                defaultEditorState={editorState}
                                onEditorStateChange={handleEditorChange}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <br />

                      {
                        engInput && (
                          <>
                            {/* description */}
                            <div className="add-blog">
                              <Row>
                                <Col xs={24}>
                                  <Form.Item
                                    name="descriptionEng"
                                    label="Description (Eng)"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input blog description!',
                                      },
                                    ]}
                                  >
                                    <Editor
                                      defaultEditorState={editorStateEng}
                                      onEditorStateChange={handleEditorChangeEng}
                                      wrapperClassName="wrapper-class"
                                      editorClassName="editor-class"
                                      toolbarClassName="toolbar-class"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                            <br />
                          </>
                        )
                      }
                      {/* featured and blog category */}
                      <div className="add-blog" >
                        <Row>
                          <Col xs={11} style={{ width: '100%' }}>
                            <Form.Item
                              name="featured"
                              label="Is Blog Featured ?"
                              rules={[
                                {
                                  required: true,
                                  message: 'Blog Featured!',
                                },
                              ]}
                            >
                              <Select style={{ width: '100%' }} placeholder="Is blog featured">
                                <Select.Option value={true}>Yes</Select.Option>
                                <Select.Option value={false}>No</Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={1}></Col>
                          <Col xs={12} style={{ width: '100%' }}>
                            <Form.Item
                              name="category"
                              label="Blog Category"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select blog category!',
                                },
                              ]}
                            >
                              <Select style={{ width: '100%' }} placeholder="Choose Blog Category">
                                {categories.map(category => <Select.Option value={category._id} key={category._id}>{category.details[dataIndex]?.category}</Select.Option>)}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <br />
                      {/* author and brand */}
                      <div className="add-blog">
                        <Row>
                          <Col xs={11}>
                            <Form.Item name="author" label="Author">
                              <Select placeholder="Choose author">
                                {users.map(user => <Select.Option value={user._id} key={user._id}>{user.name}</Select.Option>)}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={1}></Col>
                          <Col xs={12}>
                            <Form.Item name="brand" label="Brand">
                              <Select
                                placeholder="Choose brand"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().includes(input.toLowerCase())
                                }
                              >
                                {brands.map(brand => <Select.Option value={brand._id} key={brand._id}>{brand.brand}</Select.Option>)}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <br />
                      {/* image  */}
                      <div className="add-blog">
                        <Row>
                          <Col xs={11}>
                            <Form.Item
                              name="image"
                              label="Blog Main Image"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select blog image!',
                                },
                              ]}
                            >
                              <Input type={'file'} onChange={(e) => setImage(e.target.files[0])} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <br />
                      <br />
                      <Form.Item
                        wrapperCol={{
                          offset: 10,
                          span: 16,
                        }}
                      >
                        <Button style={{ backgroundColor: '#155BDC', color: '#fff' }} htmlType="submit">
                          + Add New Blog
                        </Button>
                      </Form.Item>
                    </BasicFormWrapper>
                  </Form>
                </BasicFormWrapper>
              </div>
            </Col>
            <Col xs={1}></Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

export default AddBlog;