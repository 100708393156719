import actions from './actions';

const {
  GET_REVIEW_BEGIN, GET_REVIEW_SUCCESS, GET_REVIEW_FAILED,
  UPDATE_REVIEW_BEGIN, UPDATE_REVIEW_SUCCESS, UPDATE_REVIEW_FAILED,
  DELETE_REVIEW_BEGIN, DELETE_REVIEW_SUCCESS, DELETE_REVIEW_FAILED
} = actions;

const initState = {
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  reviews: [],
  loading: false,
  error: null,
};


const ReviewReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_REVIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_REVIEW_SUCCESS:
      return {
        ...state,
        total: data.total,
        reviews: data.reviews,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: false,
      };
    case GET_REVIEW_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_REVIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: data,
        loading: false,
      };
    case UPDATE_REVIEW_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_REVIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_REVIEW_SUCCESS:
      return {
        ...state,
        total: state.total - 1,
        reviews: data,
        loading: false,
      };
    case DELETE_REVIEW_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default ReviewReducer;