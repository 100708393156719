import actions from './actions';
import axios, { CancelToken } from '../axios';
import store from '../store';
import { message } from 'antd';
import errorHandling from '../error';
import Cookies from 'js-cookie';

const {
  getUserBegin,
  getUserSuccess,
  getUserFailed,
  addUserBegin,
  addUserSuccess,
  addUserFailed,
  updateUserBegin,
  updateUserSuccess,
  updateUserFailed,
  deleteUserBegin,
  deleteUserSuccess,
  deleteUserFailed,
  getUserOneBegin,
  getUserOneSuccess,
  getUserOneFailed,
} = actions;

// GETTING ALL USERS
let cancelToken;
export const getAllUsers = filter => async dispatch => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getUserSuccess({ users: [] }));
    dispatch(getUserBegin());
    const res = await axios.get(`users${filter ? filter : ''}`, {
      headers: { Authorization: `Bearer ${Cookies.get('authToken')}` },
      cancelToken: cancelToken?.token,
    });
    const { users, total, currentPage, pageLimit } = res.data;
    dispatch(getUserSuccess({ users, total, currentPage, pageLimit }));
  } catch (err) {
    dispatch(getUserFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
  }
};

export const getUsers = id => async dispatch => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = CancelToken.source();
  try {
    dispatch(getUserOneSuccess({ users: [] }));
    dispatch(getUserOneBegin());
    const res = await axios.get(`users/${id}`, {
      headers: { Authorization: `Bearer ${Cookies.get('authToken')}` },
      cancelToken: cancelToken?.token,
    });
    const resFav = await axios.get(`favourite/?user=${id}`, {
      headers: { Authorization: `Bearer ${Cookies.get('authToken')}` },
      cancelToken: cancelToken?.token,
    });
    const resAlert = await axios.get(`alert/?user=${id}`, {
      headers: { Authorization: `Bearer ${Cookies.get('authToken')}` },
      cancelToken: cancelToken?.token,
    });

    const { user } = res.data;
    dispatch(getUserOneSuccess({ users: user, fav: resFav.data.favourites, alert: resAlert.data.alerts }));
  } catch (err) {
    dispatch(getUserOneFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err, false));
  }
};

// ADDING NEW USER
export const addNewUser = (data, form) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(addUserBegin());
    const res = await axios.post('users', data, { headers: { Authorization: `Bearer ${Cookies.get('authToken')}` } });
    const newUsers = [res.data.user, ...state.user.users];

    dispatch(addUserSuccess(newUsers));
    message.success('New user created successfully');
    form.resetFields();
  } catch (err) {
    dispatch(addUserFailed(err?.message));
    dispatch(errorHandling(err));
  }
};

// UPDATING USER
export const editUser = (id, datas) => async dispatch => {
  try {
    const state = store.getState();
    dispatch(updateUserBegin());
    const res = await axios.put(`users/${id}`, datas, {
      headers: { Authorization: `Bearer ${Cookies.get('authToken')}` },
    });
    const newUsers = state.user.users.map(user => {
      if (user._id === id) return res.data.user;
      else return user;
    });
    dispatch(updateUserSuccess(newUsers));
    message.success('User updated successfully');
  } catch (err) {
    dispatch(updateUserFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};

// DELETE USER
export const deleteUser = id => async dispatch => {
  try {
    const state = store.getState();
    dispatch(deleteUserBegin());
    await axios.delete(`users/${id}`, { headers: { Authorization: `Bearer ${Cookies.get('authToken')}` } });

    dispatch(deleteUserSuccess(state.user.users.filter(user => user._id !== id)));
    message.success('User deleted successfully');
  } catch (err) {
    dispatch(deleteUserFailed(err?.response?.data?.msg));
    dispatch(errorHandling(err));
  }
};
