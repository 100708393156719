const actions = {
  GET_VEHICLE_BEGIN: 'GET_VEHICLE_BEGIN',
  GET_VEHICLE_SUCCESS: 'GET_VEHICLE_SUCCESS',
  GET_VEHICLE_FAILED: 'GET_VEHICLE_FAILED',

  GET_VEHICLES_BEGIN: 'GET_VEHICLES_BEGIN',
  GET_VEHICLES_SUCCESS: 'GET_VEHICLES_SUCCESS',
  GET_VEHICLES_FAILED: 'GET_VEHICLES_FAILED',

  GET_VEHICLES_BEGIN: 'GET_VEHICLES_BEGIN',
  GET_VEHICLES_SUCCESS: 'GET_VEHICLES_SUCCESS',
  GET_VEHICLES_FAILED: 'GET_VEHICLES_FAILED',

  ADD_VEHICLE_BEGIN: 'ADD_VEHICLE_BEGIN',
  ADD_VEHICLE_SUCCESS: 'ADD_VEHICLE_SUCCESS',
  ADD_VEHICLE_FAILED: 'ADD_VEHICLE_FAILED',

  UPDATE_VEHICLE_BEGIN: 'UPDATE_VEHICLE_BEGIN',
  UPDATE_VEHICLE_SUCCESS: 'UPDATE_VEHICLE_SUCCESS',
  UPDATE_VEHICLE_FAILED: 'UPDATE_VEHICLE_FAILED',

  DELETE_VEHICLE_BEGIN: 'DELETE_VEHICLE_BEGIN',
  DELETE_VEHICLE_SUCCESS: 'DELETE_VEHICLE_SUCCESS',
  DELETE_VEHICLE_FAILED: 'DELETE_VEHICLE_FAILED',

  getVehicleBegin: () => {
    return {
      type: actions.GET_VEHICLE_BEGIN,
    };
  },

  getVehicleSuccess: data => {
    return {
      type: actions.GET_VEHICLE_SUCCESS,
      data,
    };
  },

  getVehicleFailed: err => {
    return {
      type: actions.GET_VEHICLE_FAILED,
      err,
    };
  },

  getVehiclesBegin: () => {
    return {
      type: actions.GET_VEHICLES_BEGIN,
    };
  },

  getVehiclesSuccess: data => {
    return {
      type: actions.GET_VEHICLES_SUCCESS,
      data,
    };
  },

  getVehiclesFailed: err => {
    return {
      type: actions.GET_VEHICLES_FAILED,
      err,
    };
  },

  addVehicleBegin: () => {
    return {
      type: actions.ADD_VEHICLE_BEGIN,
    };
  },

  addVehicleSuccess: data => {
    return {
      type: actions.ADD_VEHICLE_SUCCESS,
      data,
    };
  },

  addVehicleFailed: err => {
    return {
      type: actions.ADD_VEHICLE_FAILED,
      err,
    };
  },
  updateVehicleBegin: () => {
    return {
      type: actions.UPDATE_VEHICLE_BEGIN,
    };
  },

  updateVehicleSuccess: data => {
    return {
      type: actions.UPDATE_VEHICLE_SUCCESS,
      data,
    };
  },

  updateVehicleFailed: err => {
    return {
      type: actions.UPDATE_VEHICLE_FAILED,
      err,
    };
  },
  deleteVehicleBegin: () => {
    return {
      type: actions.DELETE_VEHICLE_BEGIN,
    };
  },

  deleteVehicleSuccess: data => {
    return {
      type: actions.DELETE_VEHICLE_SUCCESS,
      data,
    };
  },

  deleteVehicleFailed: err => {
    return {
      type: actions.DELETE_VEHICLE_FAILED,
      err,
    };
  },
};

export default actions;