import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {/* FOR DASHBOARD */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}`}>
              <FeatherIcon icon="home" />
            </NavLink>
          )
        }
        key="dashboard"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Dashboard
        </NavLink>
      </Menu.Item>

      {/* FOR USER  */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/users`}>
              <FeatherIcon icon="users" />
            </NavLink>
          )
        }
        key="user"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
          Users
        </NavLink>
      </Menu.Item>

      {/* FOR VEHICLES + BRAND + MODEL +BODY TYPE +COLOR |+VEHICLE +COMPARE VEHICLE +VEHICLE COLOR*/}
      <SubMenu key="vehicle-data" icon={!topMenu && <FeatherIcon icon="truck" />} title="Vehicle">
        {/* FOR BRAND */}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/brands`}>
                <FeatherIcon icon="pocket" />
              </NavLink>
            )
          }
          key="brand"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/brands`}>
            Brand
          </NavLink>
        </Menu.Item>
        {/* FOR BODY TYPE */}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/body-types`}>
                <FeatherIcon icon="framer" />
              </NavLink>
            )
          }
          key="bodytype"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/body-types`}>
            Body Type
          </NavLink>
        </Menu.Item>

        {/* FOR MODEL */}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/models`}>
                <FeatherIcon icon="zap" />
              </NavLink>
            )
          }
          key="model"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/models`}>
            Model
          </NavLink>
        </Menu.Item>

        {/* FOR VARIANT */}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/variants`}>
                <FeatherIcon icon="feather" />
              </NavLink>
            )
          }
          key="variant"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/variants`}>
            Variant
          </NavLink>
        </Menu.Item>

        {/* FOR COLOR */}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/colors`}>
                <FeatherIcon icon="bold" />
              </NavLink>
            )
          }
          key="vehiclecolor"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/colors`}>
            Color
          </NavLink>
        </Menu.Item>

        {/* FOR VEHICLE  */}
        {/* <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/vehicles`}>
                <FeatherIcon icon="truck" />
              </NavLink>
            )
          }
          key="vehicle"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/vehicles`}>
            Vehicle
          </NavLink>
        </Menu.Item> */}

        {/* FOR VEHICLE COMPARING */}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/compare-vehicles`}>
                <FeatherIcon icon="thumbs-up" />
              </NavLink>
            )
          }
          key="vehicle comparing"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/compare-vehicles`}>
            Compare Vehicle
          </NavLink>
        </Menu.Item>

        {/* FOR VEHICLE COLOR */}
        {/* <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/vehicle-colors`}>
                <FeatherIcon icon="pen-tool" />
              </NavLink>
            )
          }
          key="vehicleavailablecolor"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/vehicle-colors`}>
            Vehicle Color
          </NavLink>
        </Menu.Item> */}
      </SubMenu>

      {/* FOR BLOGS +BLOG CATEGORY */}
      <SubMenu key="layout" icon={!topMenu && <FeatherIcon icon="layout" />} title="Blogs">
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/blog-categories`}>
                <FeatherIcon icon="copy" />
              </NavLink>
            )
          }
          key="category"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/blog-categories`}>
            Category
          </NavLink>
        </Menu.Item>

        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/blogs`}>
                <FeatherIcon icon="layout" />
              </NavLink>
            )
          }
          key="blog"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/blogs`}>
            Blogs
          </NavLink>
        </Menu.Item>

        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}/blog-videos`}>
                <FeatherIcon icon="youtube" />
              </NavLink>
            )
          }
          key="blog-video"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/blog-videos`}>
            Blog Video
          </NavLink>
        </Menu.Item>
      </SubMenu>

      {/* FOR ADS  */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/ads`}>
              <FeatherIcon icon="book" />
            </NavLink>
          )
        }
        key="ads"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/ads`}>
          Advertisement
        </NavLink>
      </Menu.Item>

      {/* FOR SUBSCRIBER  */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/subscribers`}>
              <FeatherIcon icon="user-check" />
            </NavLink>
          )
        }
        key="subscriber"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/subscribers`}>
          Subscribers
        </NavLink>
      </Menu.Item>

      {/* FAQS */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/faqs`}>
              <FeatherIcon icon="help-circle" />
            </NavLink>
          )
        }
        key="faq"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/faqs`}>
          FAQ
        </NavLink>
      </Menu.Item>

      {/* REVIEWS */}
      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/reviews`}>
              <FeatherIcon icon="message-circle" />
            </NavLink>
          )
        }
        key="review"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/reviews`}>
          Reviews
        </NavLink>
      </Menu.Item> */}

      {/* Ratings */}
      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/ratings`}>
              <FeatherIcon icon="star" />
            </NavLink>
          )
        }
        key="rating"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/ratings`}>
          Ratings
        </NavLink>
      </Menu.Item> */}

      {/* reviewratings */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/review&ratings`}>
              <FeatherIcon icon="star" />
            </NavLink>
          )
        }
        key="rating"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/review&ratings`}>
          Review & Ratings
        </NavLink>
      </Menu.Item>

      {/* Sliders */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/sliders`}>
              <FeatherIcon icon="sliders" />
            </NavLink>
          )
        }
        key="slider"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/sliders`}>
          Slider
        </NavLink>
      </Menu.Item>

      {/* PAGES */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/pages`}>
              <FeatherIcon icon="copy" />
            </NavLink>
          )
        }
        key="pages"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/pages`}>
          Page
        </NavLink>
      </Menu.Item>

      {/* callbacks */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/callbacks`}>
              <FeatherIcon icon="help-circle" />
            </NavLink>
          )
        }
        key="callbacks"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/callbacks`}>
          CallBack
        </NavLink>
      </Menu.Item>

      {/* FOR DESCRIPTION */}
      <Menu.Item
              icon={
               !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}/descriptions`}>
                    <FeatherIcon icon="copy" />
                  </NavLink>
                )
              }
              key="description"
            >
              <NavLink onClick={toggleCollapsed} to={`${path}/descriptions`}>
                Description
              </NavLink>
            </Menu.Item>

      {/* FOR CONTACT INFORMATION  */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/contact-information`}>
              <FeatherIcon icon="map-pin" />
            </NavLink>
          )
        }
        key="contact-information"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/contact-information`}>
          Contact Information
        </NavLink>
      </Menu.Item>

      {/* FOR CONTACT QUERY  */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path}/contact-queries`}>
              <FeatherIcon icon="phone-call" />
            </NavLink>
          )
        }
        key="contact-query"
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/contact-queries`}>
          Contact Query
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
