import React from 'react';
import propTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Admin from '../../routes/admin';
import Writer from '../../routes';

const ProtectedRoute = () => {
  const { isLoggedIn, user } = useSelector(state => {
    return {
      isLoggedIn: state.auth.login,
      user: state.auth.user,
    };
  });
  return isLoggedIn ? (
    user?.role?.name === 'admin' ? (
      <Route component={Admin} path={'/admin'} />
    ) : user?.role?.name === 'writer' ? (
      <Route component={Writer} path={'/writer'} />
    ) : (
      <Redirect to="/" />
    )
  ) : (
    <Redirect to="/" />
  );
};

ProtectedRoute.propTypes = {
  component: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
};

export default ProtectedRoute;
