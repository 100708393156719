const actions = {
  GET_BRAND_BEGIN: 'GET_BRAND_BEGIN',
  GET_BRAND_SUCCESS: 'GET_BRAND_SUCCESS',
  GET_BRAND_FAILED: 'GET_BRAND_FAILED',

  GET_VEHICLES_BRAND_SUCCESS: 'GET_VEHICLES_BRAND_SUCCESS',
  GET_VEHICLES_BRAND_BEGIN: 'GET_VEHICLES_BRAND_BEGIN',
  GET_VEHICLES_BRAND_FAILED: 'GET_VEHICLES_BRAND_FAILED',

  ADD_BRAND_BEGIN: 'ADD_BRAND_BEGIN',
  ADD_BRAND_SUCCESS: 'ADD_BRAND_SUCCESS',
  ADD_BRAND_FAILED: 'ADD_BRAND_FAILED',

  UPDATE_BRAND_BEGIN: 'UPDATE_BRAND_BEGIN',
  UPDATE_BRAND_SUCCESS: 'UPDATE_BRAND_SUCCESS',
  UPDATE_BRAND_FAILED: 'UPDATE_BRAND_FAILED',

  DELETE_BRAND_BEGIN: 'DELETE_BRAND_BEGIN',
  DELETE_BRAND_SUCCESS: 'DELETE_BRAND_SUCCESS',
  DELETE_BRAND_FAILED: 'DELETE_BRAND_FAILED',

  getBrandBegin: () => {
    return {
      type: actions.GET_BRAND_BEGIN,
    };
  },

  getBrandSuccess: data => {
    return {
      type: actions.GET_BRAND_SUCCESS,
      data,
    };
  },

  getBrandFailed: err => {
    return {
      type: actions.GET_BRAND_FAILED,
      err,
    };
  },

  getVehicleBrandBegin: () => {
    return {
      type: actions.GET_VEHICLES_BRAND_BEGIN,
    };
  },

  getVehicleBrandSuccess: data => {
    return {
      type: actions.GET_VEHICLES_BRAND_SUCCESS,
      data,
    };
  },

  getVehicleBrandFailed: err => {
    return {
      type: actions.GET_VEHICLES_BRAND_FAILED,
      err,
    };
  },

  addBrandBegin: () => {
    return {
      type: actions.ADD_BRAND_BEGIN,
    };
  },

  addBrandSuccess: data => {
    return {
      type: actions.ADD_BRAND_SUCCESS,
      data,
    };
  },

  addBrandFailed: err => {
    return {
      type: actions.ADD_BRAND_FAILED,
      err,
    };
  },
  updateBrandBegin: () => {
    return {
      type: actions.UPDATE_BRAND_BEGIN,
    };
  },

  updateBrandSuccess: data => {
    return {
      type: actions.UPDATE_BRAND_SUCCESS,
      data,
    };
  },

  updateBrandFailed: err => {
    return {
      type: actions.UPDATE_BRAND_FAILED,
      err,
    };
  },
  deleteBrandBegin: () => {
    return {
      type: actions.DELETE_BRAND_BEGIN,
    };
  },

  deleteBrandSuccess: data => {
    return {
      type: actions.DELETE_BRAND_SUCCESS,
      data,
    };
  },

  deleteBrandFailed: err => {
    return {
      type: actions.DELETE_BRAND_FAILED,
      err,
    };
  },
};

export default actions;
