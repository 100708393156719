const actions = {
  GET_COMPARE_VEHICLE_BEGIN: 'GET_COMPARE_VEHICLE_BEGIN',
  GET_COMPARE_VEHICLE_SUCCESS: 'GET_COMPARE_VEHICLE_SUCCESS',
  GET_COMPARE_VEHICLE_FAILED: 'GET_COMPARE_VEHICLE_FAILED',

  ADD_COMPARE_VEHICLE_BEGIN: 'ADD_COMPARE_VEHICLE_BEGIN',
  ADD_COMPARE_VEHICLE_SUCCESS: 'ADD_COMPARE_VEHICLE_SUCCESS',
  ADD_COMPARE_VEHICLE_FAILED: 'ADD_COMPARE_VEHICLE_FAILED',

  UPDATE_COMPARE_VEHICLE_BEGIN: 'UPDATE_COMPARE_VEHICLE_BEGIN',
  UPDATE_COMPARE_VEHICLE_SUCCESS: 'UPDATE_COMPARE_VEHICLE_SUCCESS',
  UPDATE_COMPARE_VEHICLE_FAILED: 'UPDATE_COMPARE_VEHICLE_FAILED',

  DELETE_COMPARE_VEHICLE_BEGIN: 'DELETE_COMPARE_VEHICLE_BEGIN',
  DELETE_COMPARE_VEHICLE_SUCCESS: 'DELETE_COMPARE_VEHICLE_SUCCESS',
  DELETE_COMPARE_VEHICLE_FAILED: 'DELETE_COMPARE_VEHICLE_FAILED',

  GET_USER_COMPARE_VEHICLE_BEGIN: 'GET_USER_COMPARE_VEHICLE_BEGIN',
  GET_USER_COMPARE_VEHICLE_SUCCESS: 'GET_USER_COMPARE_VEHICLE_SUCCESS',
  GET_USER_COMPARE_VEHICLE_FAILED: 'GET_USER_COMPARE_VEHICLE_FAILED',

  getCompareVehicleBegin: () => {
    return {
      type: actions.GET_COMPARE_VEHICLE_BEGIN,
    };
  },

  getCompareVehicleSuccess: data => {
    return {
      type: actions.GET_COMPARE_VEHICLE_SUCCESS,
      data,
    };
  },

  getCompareVehicleFailed: err => {
    return {
      type: actions.GET_COMPARE_VEHICLE_FAILED,
      err,
    };
  },

  getUserCompareVehicleBegin: () => {
    return {
      type: actions.GET_USER_COMPARE_VEHICLE_BEGIN,
    };
  },

  getUserCompareVehicleSuccess: data => {
    return {
      type: actions.GET_USER_COMPARE_VEHICLE_SUCCESS,
      data,
    };
  },

  getUserCompareVehicleFailed: err => {
    return {
      type: actions.GET_USER_COMPARE_VEHICLE_FAILED,
      err,
    };
  },

  addCompareVehicleBegin: () => {
    return {
      type: actions.ADD_COMPARE_VEHICLE_BEGIN,
    };
  },

  addCompareVehicleSuccess: data => {
    return {
      type: actions.ADD_COMPARE_VEHICLE_SUCCESS,
      data,
    };
  },

  addCompareVehicleFailed: err => {
    return {
      type: actions.ADD_COMPARE_VEHICLE_FAILED,
      err,
    };
  },
  updateCompareVehicleBegin: () => {
    return {
      type: actions.UPDATE_COMPARE_VEHICLE_BEGIN,
    };
  },

  updateCompareVehicleSuccess: data => {
    return {
      type: actions.UPDATE_COMPARE_VEHICLE_SUCCESS,
      data,
    };
  },

  updateCompareVehicleFailed: err => {
    return {
      type: actions.UPDATE_COMPARE_VEHICLE_FAILED,
      err,
    };
  },
  deleteCompareVehicleBegin: () => {
    return {
      type: actions.DELETE_COMPARE_VEHICLE_BEGIN,
    };
  },

  deleteCompareVehicleSuccess: data => {
    return {
      type: actions.DELETE_COMPARE_VEHICLE_SUCCESS,
      data,
    };
  },

  deleteCompareVehicleFailed: err => {
    return {
      type: actions.DELETE_COMPARE_VEHICLE_FAILED,
      err,
    };
  },
};

export default actions;
