import actions from './actions';

const {
  GET_SLIDER_BEGIN, GET_SLIDER_SUCCESS, GET_SLIDER_FAILED,
  ADD_SLIDER_BEGIN, ADD_SLIDER_SUCCESS, ADD_SLIDER_FAILED,
  UPDATE_SLIDER_BEGIN, UPDATE_SLIDER_SUCCESS, UPDATE_SLIDER_FAILED,
  DELETE_SLIDER_BEGIN, DELETE_SLIDER_SUCCESS, DELETE_SLIDER_FAILED,
} = actions;

const initState = {
  total: 0,
  currentPage: 1,
  pageLimit: 8,
  sliders: [],
  loading: false,
  error: null,
};

const SliderReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SLIDER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SLIDER_SUCCESS:
      return {
        ...state,
        total: data.total,
        sliders: data.sliders,
        currentPage: data.currentPage || state.currentPage,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: data.currentPage ? false : true,
      };
    case GET_SLIDER_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_SLIDER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_SLIDER_SUCCESS:
      return {
        ...state,
        total: state.total + 1,
        sliders: data,
        loading: false,
      };
    case ADD_SLIDER_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_SLIDER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SLIDER_SUCCESS:
      return {
        ...state,
        sliders: data,
        loading: false,
      };
    case UPDATE_SLIDER_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_SLIDER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SLIDER_SUCCESS:
      return {
        ...state,
        total: state.total - 1,
        sliders: data,
        loading: false,
      };
    case DELETE_SLIDER_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default SliderReducer;