const actions = {
  GET_CONTACT_QUERY_BEGIN: 'GET_CONTACT_QUERY_BEGIN',
  GET_CONTACT_QUERY_SUCCESS: 'GET_CONTACT_QUERY_SUCCESS',
  GET_CONTACT_QUERY_FAILED: 'GET_CONTACT_QUERY_FAILED',

  DELETE_CONTACT_QUERY_BEGIN: 'DELETE_CONTACT_QUERY_BEGIN',
  DELETE_CONTACT_QUERY_SUCCESS: 'DELETE_CONTACT_QUERY_SUCCESS',
  DELETE_CONTACT_QUERY_FAILED: 'DELETE_CONTACT_QUERY_FAILED',

  getContactQueryBegin: () => {
    return {
      type: actions.GET_CONTACT_QUERY_BEGIN,
    };
  },

  getContactQuerySuccess: data => {
    return {
      type: actions.GET_CONTACT_QUERY_SUCCESS,
      data,
    };
  },

  getContactQueryFailed: err => {
    return {
      type: actions.GET_CONTACT_QUERY_FAILED,
      err,
    };
  },

  deleteContactQueryBegin: () => {
    return {
      type: actions.DELETE_CONTACT_QUERY_BEGIN,
    };
  },

  deleteContactQuerySuccess: data => {
    return {
      type: actions.DELETE_CONTACT_QUERY_SUCCESS,
      data,
    };
  },

  deleteContactQueryFailed: err => {
    return {
      type: actions.DELETE_CONTACT_QUERY_FAILED,
      err,
    };
  },
};

export default actions;
