const actions = {
  GET_REVIEW_BEGIN: 'GET_REVIEW_BEGIN',
  GET_REVIEW_SUCCESS: 'GET_REVIEW_SUCCESS',
  GET_REVIEW_FAILED: 'GET_REVIEW_FAILED',

  UPDATE_REVIEW_BEGIN: 'UPDATE_REVIEW_BEGIN',
  UPDATE_REVIEW_SUCCESS: 'UPDATE_REVIEW_SUCCESS',
  UPDATE_REVIEW_FAILED: 'UPDATE_REVIEW_FAILED',

  DELETE_REVIEW_BEGIN: 'DELETE_REVIEW_BEGIN',
  DELETE_REVIEW_SUCCESS: 'DELETE_REVIEW_SUCCESS',
  DELETE_REVIEW_FAILED: 'DELETE_REVIEW_FAILED',

  getReviewBegin: () => {
    return {
      type: actions.GET_REVIEW_BEGIN,
    };
  },

  getReviewSuccess: data => {
    return {
      type: actions.GET_REVIEW_SUCCESS,
      data,
    };
  },

  getReviewFailed: err => {
    return {
      type: actions.GET_REVIEW_FAILED,
      err,
    };
  },

  updateReviewBegin: () => {
    return {
      type: actions.UPDATE_REVIEW_BEGIN,
    };
  },

  updateReviewSuccess: data => {
    return {
      type: actions.UPDATE_REVIEW_SUCCESS,
      data,
    };
  },

  updateReviewFailed: err => {
    return {
      type: actions.UPDATE_REVIEW_FAILED,
      err,
    };
  },

  deleteReviewBegin: () => {
    return {
      type: actions.DELETE_REVIEW_BEGIN,
    };
  },

  deleteReviewSuccess: data => {
    return {
      type: actions.DELETE_REVIEW_SUCCESS,
      data,
    };
  },

  deleteReviewFailed: err => {
    return {
      type: actions.DELETE_REVIEW_FAILED,
      err,
    };
  },
};

export default actions;