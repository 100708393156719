import actions from './actions';

const {
  GET_DESCRIPTION_BEGIN,
  GET_DESCRIPTION_SUCCESS,
  GET_DESCRIPTION_FAILED,
  ADD_DESCRIPTION_BEGIN,
  ADD_DESCRIPTION_SUCCESS,
  ADD_DESCRIPTION_FAILED,
  UPDATE_DESCRIPTION_BEGIN,
  UPDATE_DESCRIPTION_SUCCESS,
  UPDATE_DESCRIPTION_FAILED,
  DELETE_DESCRIPTION_BEGIN,
  DELETE_DESCRIPTION_SUCCESS,
  DELETE_DESCRIPTION_FAILED,
} = actions;

const initState = {
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  descriptions: [],
  loading: false,
  error: null,
};

const DescriptionReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_DESCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_DESCRIPTION_SUCCESS:
      return {
        ...state,
        descriptions: data.descriptions,
        total: data.total || 0,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: data.currentPage ? false : true,
      };
    case GET_DESCRIPTION_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case ADD_DESCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_DESCRIPTION_SUCCESS:
      return {
        ...state,
        models: data,
        total: state.total + 1,
        loading: false,
      };
    case ADD_DESCRIPTION_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_DESCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_DESCRIPTION_SUCCESS:
      return {
        ...state,
        models: data,
        loading: false,
      };
    case UPDATE_DESCRIPTION_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_DESCRIPTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_DESCRIPTION_SUCCESS:
      return {
        ...state,
        models: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_DESCRIPTION_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default DescriptionReducer;
