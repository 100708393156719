// ==================================== COMPONENTS =========================================
// THIRD PARTY/ CORE COMPONENTS
import React, { useEffect, useState } from 'react';
import { Input, Row, Col } from 'antd';
import { useLocation } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// USER COMPONENTS
import { Div } from './header-search-style';
import { headerSearchAction, clearHeaderSearch } from '../../redux/headerSearch/actionCreator';

const HeaderSearch = ({ darkMode }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [visible, setVisible] = useState(true);
  const rtl = useSelector(state => state.ChangeLayoutMode.rtlData);
  const { searchKeyword, currentPath, pathLength } = useSelector(state => {
    return {
      searchKeyword: state.headerSearch.searchKeyword,
      currentPath: state.headerSearch.path,
      pathLength: state.headerSearch.pathLength,
    }
  })

  useEffect(() => {
    const splitCurrentPath = location.pathname.split('/');
    if (splitCurrentPath.length <= 2)
      setVisible(false);
    else {
      const path = splitCurrentPath[2];
      if (path === 'sliders' || path === 'body-types' || path === 'colors' || path === 'blog-categories' || path === 'ads' || path === 'contact-information' || path === 'faqs' || path === 'profile') setVisible(false);
      else setVisible(true);
    }
    search();
  }, [location.pathname]);

  const search = (e = undefined) => {
    const splitCurrentPath = location.pathname.split('/');
    if (splitCurrentPath[2]) {
      const path = splitCurrentPath[2];
      if ((currentPath === path)) {
        if (splitCurrentPath.length !== pathLength)
          dispatch(headerSearchAction(e?.target?.value || searchKeyword, path, splitCurrentPath.length));
        else
          dispatch(headerSearchAction(e?.target?.value || '', path, splitCurrentPath.length));
      }
      else dispatch(clearHeaderSearch(path, splitCurrentPath.length));
    }
  };

  const clearField = () => {
    const splitCurrentPath = location.pathname.split('/');
    if (splitCurrentPath[2]) {
      const path = splitCurrentPath[2];
      dispatch(clearHeaderSearch(path, splitCurrentPath.length));
    }
  };



  return (
    <>
      <Div className="certain-category-search-wrapper" style={{ width: '100%' }} darkMode={darkMode}>
        <Row className="ant-row-middle">
          {
            visible && (
              <>
                <Col md={2} xs={1} className={rtl ? 'text-left' : 'text-right'}>
                  <span className="certain-category-icon">
                    <FeatherIcon icon="search" size={16} />
                  </span>
                </Col>
                <Col md={22} xs={23} style={{ display: 'flex', alignItems: 'center' }}>
                  <Input placeholder="Search..." onChange={search} value={searchKeyword} bordered={false} />
                  {
                    searchKeyword.length > 0 && (
                      <FeatherIcon icon="x-circle" size={16} style={{ cursor: 'pointer' }} onClick={clearField} />
                    )
                  }
                </Col>
              </>
            )
          }
        </Row>
      </Div>
    </>
  );
};

HeaderSearch.propTypes = {
  darkMode: PropTypes.bool,
};

export default HeaderSearch;
