const actions = {
  GET_BLOG_VIDEO_BEGIN: 'GET_BLOG_VIDEO_BEGIN',
  GET_BLOG_VIDEO_SUCCESS: 'GET_BLOG_VIDEO_SUCCESS',
  GET_BLOG_VIDEO_FAILED: 'GET_BLOG_VIDEO_FAILED',

  ADD_BLOG_VIDEO_BEGIN: 'ADD_BLOG_VIDEO_BEGIN',
  ADD_BLOG_VIDEO_SUCCESS: 'ADD_BLOG_VIDEO_SUCCESS',
  ADD_BLOG_VIDEO_FAILED: 'ADD_BLOG_VIDEO_FAILED',

  UPDATE_BLOG_VIDEO_BEGIN: 'UPDATE_BLOG_VIDEO_BEGIN',
  UPDATE_BLOG_VIDEO_SUCCESS: 'UPDATE_BLOG_VIDEO_SUCCESS',
  UPDATE_BLOG_VIDEO_FAILED: 'UPDATE_BLOG_VIDEO_FAILED',

  DELETE_BLOG_VIDEO_BEGIN: 'DELETE_BLOG_VIDEO_BEGIN',
  DELETE_BLOG_VIDEO_SUCCESS: 'DELETE_BLOG_VIDEO_SUCCESS',
  DELETE_BLOG_VIDEO_FAILED: 'DELETE_BLOG_VIDEO_FAILED',

  getBlogVideoBegin: () => {
    return {
      type: actions.GET_BLOG_VIDEO_BEGIN,
    };
  },

  getBlogVideoSuccess: data => {
    return {
      type: actions.GET_BLOG_VIDEO_SUCCESS,
      data,
    };
  },

  getBlogVideoFailed: err => {
    return {
      type: actions.GET_BLOG_VIDEO_FAILED,
      err,
    };
  },

  addBlogVideoBegin: () => {
    return {
      type: actions.ADD_BLOG_VIDEO_BEGIN,
    };
  },

  addBlogVideoSuccess: data => {
    return {
      type: actions.ADD_BLOG_VIDEO_SUCCESS,
      data,
    };
  },

  addBlogVideoFailed: err => {
    return {
      type: actions.ADD_BLOG_VIDEO_FAILED,
      err,
    };
  },

  updateBlogVideoBegin: () => {
    return {
      type: actions.UPDATE_BLOG_VIDEO_BEGIN,
    };
  },

  updateBlogVideoSuccess: data => {
    return {
      type: actions.UPDATE_BLOG_VIDEO_SUCCESS,
      data,
    };
  },

  updateBlogVideoFailed: err => {
    return {
      type: actions.UPDATE_BLOG_VIDEO_FAILED,
      err,
    };
  },

  deleteBlogVideoBegin: () => {
    return {
      type: actions.DELETE_BLOG_VIDEO_BEGIN,
    };
  },

  deleteBlogVideoSuccess: data => {
    return {
      type: actions.DELETE_BLOG_VIDEO_SUCCESS,
      data,
    };
  },

  deleteBlogVideoFailed: err => {
    return {
      type: actions.DELETE_BLOG_VIDEO_FAILED,
      err,
    };
  },
};

export default actions;