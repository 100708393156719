const actions = {
  GET_VARIANT_BEGIN: 'GET_VARIANT_BEGIN',
  GET_VARIANT_SUCCESS: 'GET_VARIANT_SUCCESS',
  GET_VARIANT_FAILED: 'GET_VARIANT_FAILED',

  GET_VARIANT_BY_MODEL_BEGIN: 'GET_VARIANT_BY_MODEL_BEGIN',
  GET_VARIANT_BY_MODEL_SUCCESS: 'GET_VARIANT_BY_MODEL_SUCCESS',
  GET_VARIANT_BY_MODEL_FAILED: 'GET_VARIANT_BY_MODEL_FAILED',

  ADD_VARIANT_BEGIN: 'ADD_VARIANT_BEGIN',
  ADD_VARIANT_SUCCESS: 'ADD_VARIANT_SUCCESS',
  ADD_VARIANT_FAILED: 'ADD_VARIANT_FAILED',

  UPDATE_VARIANT_BEGIN: 'UPDATE_VARIANT_BEGIN',
  UPDATE_VARIANT_SUCCESS: 'UPDATE_VARIANT_SUCCESS',
  UPDATE_VARIANT_FAILED: 'UPDATE_VARIANT_FAILED',

  DELETE_VARIANT_BEGIN: 'DELETE_VARIANT_BEGIN',
  DELETE_VARIANT_SUCCESS: 'DELETE_VARIANT_SUCCESS',
  DELETE_VARIANT_FAILED: 'DELETE_VARIANT_FAILED',

  GET_ONE_VARIANT_BEGIN: 'GET_ONE_VARIANT_BEGIN',
  GET_ONE_VARIANT_SUCCESS: 'GET_ONE_VARIANT_SUCCESS',
  GET_ONE_VARIANT_FAILED: 'GET_ONE_VARIANT_FAILED',

  GET_BASE_VARIANT_BEGIN: 'GET_BASE_VARIANT_BEGIN',
  GET_BASE_VARIANT_SUCCESS: 'GET_BASE_VARIANT_SUCCESS',
  GET_BASE_VARIANT_FAILED: 'GET_BASE_VARIANT_FAILED',

  getVariantBegin: () => {
    return {
      type: actions.GET_VARIANT_BEGIN,
    };
  },

  getVariantSuccess: data => {
    return {
      type: actions.GET_VARIANT_SUCCESS,
      data,
    };
  },

  getVariantFailed: err => {
    return {
      type: actions.GET_VARIANT_FAILED,
      err,
    };
  },

  getVariantByModelBegin: () => {
    return {
      type: actions.GET_VARIANT_BY_MODEL_BEGIN,
    };
  },

  getVariantByModelSuccess: data => {
    return {
      type: actions.GET_VARIANT_BY_MODEL_SUCCESS,
      data,
    };
  },

  getVariantByModelFailed: err => {
    return {
      type: actions.GET_VARIANT_BY_MODEL_FAILED,
      err,
    };
  },

  addVariantBegin: () => {
    return {
      type: actions.ADD_VARIANT_BEGIN,
    };
  },

  addVariantSuccess: data => {
    return {
      type: actions.ADD_VARIANT_SUCCESS,
      data,
    };
  },

  addVariantFailed: err => {
    return {
      type: actions.ADD_VARIANT_FAILED,
      err,
    };
  },
  updateVariantBegin: () => {
    return {
      type: actions.UPDATE_VARIANT_BEGIN,
    };
  },

  updateVariantSuccess: data => {
    return {
      type: actions.UPDATE_VARIANT_SUCCESS,
      data,
    };
  },

  updateVariantFailed: err => {
    return {
      type: actions.UPDATE_VARIANT_FAILED,
      err,
    };
  },
  deleteVariantBegin: () => {
    return {
      type: actions.DELETE_VARIANT_BEGIN,
    };
  },

  deleteVariantSuccess: data => {
    return {
      type: actions.DELETE_VARIANT_SUCCESS,
      data,
    };
  },

  deleteVariantFailed: err => {
    return {
      type: actions.DELETE_VARIANT_FAILED,
      err,
    };
  },
  getOneVariantBegin: () => {
    return {
      type: actions.GET_ONE_VARIANT_BEGIN,
    };
  },

  getOneVariantSuccess: data => {
    return {
      type: actions.GET_ONE_VARIANT_SUCCESS,
      data,
    };
  },

  getOneVariantFailed: err => {
    return {
      type: actions.GET_ONE_VARIANT_FAILED,
      err,
    };
  },
  getBaseVariantBegin: () => {
    return {
      type: actions.GET_BASE_VARIANT_BEGIN,
    };
  },

  getBaseVariantSuccess: data => {
    return {
      type: actions.GET_BASE_VARIANT_SUCCESS,
      data,
    };
  },

  getBaseVariantFailed: err => {
    return {
      type: actions.GET_BASE_VARIANT_FAILED,
      err,
    };
  },
};

export default actions;
