const actions = {
  GET_RATING_BEGIN: 'GET_RATING_BEGIN',
  GET_RATING_SUCCESS: 'GET_RATING_SUCCESS',
  GET_RATING_FAILED: 'GET_RATING_FAILED',

  DELETE_RATING_BEGIN: 'DELETE_RATING_BEGIN',
  DELETE_RATING_SUCCESS: 'DELETE_RATING_SUCCESS',
  DELETE_RATING_FAILED: 'DELETE_RATING_FAILED',

  getRatingBegin: () => {
    return {
      type: actions.GET_RATING_BEGIN,
    };
  },

  getRatingSuccess: data => {
    return {
      type: actions.GET_RATING_SUCCESS,
      data,
    };
  },

  getRatingFailed: err => {
    return {
      type: actions.GET_RATING_FAILED,
      err,
    };
  },

  deleteRatingBegin: () => {
    return {
      type: actions.DELETE_RATING_BEGIN,
    };
  },

  deleteRatingSuccess: data => {
    return {
      type: actions.DELETE_RATING_SUCCESS,
      data,
    };
  },

  deleteRatingFailed: err => {
    return {
      type: actions.DELETE_RATING_FAILED,
      err,
    };
  },
};

export default actions;