import actions from './actions';

const {
  GET_CONTACT_QUERY_BEGIN, GET_CONTACT_QUERY_SUCCESS, GET_CONTACT_QUERY_FAILED,
  DELETE_CONTACT_QUERY_BEGIN, DELETE_CONTACT_QUERY_SUCCESS, DELETE_CONTACT_QUERY_FAILED
} = actions;

const initState = {
  queries: [],
  total: 0,
  currentPage: 1,
  pageLimit: 50,
  loading: false,
  error: null
};

const ContactQueryReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CONTACT_QUERY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CONTACT_QUERY_SUCCESS:
      return {
        ...state,
        queries: data.contactQueries,
        total: data.total || 0,
        currentPage: data.currentPage || 1,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: data.currentPage ? false : true,
      };
    case GET_CONTACT_QUERY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_CONTACT_QUERY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CONTACT_QUERY_SUCCESS:
      return {
        ...state,
        queries: data,
        total: state.total - 1,
        loading: false,
      };
    case DELETE_CONTACT_QUERY_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default ContactQueryReducer;