import actions from './actions';

const {
  GET_CALLBACK_BEGIN, GET_CALLBACK_SUCCESS, GET_CALLBACK_FAILED,
  UPDATE_CALLBACK_BEGIN, UPDATE_CALLBACK_SUCCESS, UPDATE_CALLBACK_FAILED,
  DELETE_CALLBACK_BEGIN, DELETE_CALLBACK_SUCCESS, DELETE_CALLBACK_FAILED
} = actions;

const initState = {
  total: 0,
  callbacks: [],
  currentPage: 1,
  pageLimit: 50,
  loading: false,
  error: null,
};

const CallBackReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CALLBACK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CALLBACK_SUCCESS:
      return {
        ...state,
        total: data.total || 0,
        callbacks: data.callBacks,
        currentPage: data.currentPage || state.currentPage,
        pageLimit: data.pageLimit || state.pageLimit,
        loading: data.currentPage ? false : true,
      };
    case GET_CALLBACK_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_CALLBACK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CALLBACK_SUCCESS:
      return {
        ...state,
        callbacks: data,
        loading: false,
      };
    case UPDATE_CALLBACK_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_CALLBACK_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CALLBACK_SUCCESS:
      return {
        ...state,
        total: state.total - 1,
        callbacks: data,
        loading: false,
      };
    case DELETE_CALLBACK_FAILED:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export default CallBackReducer;