import React from 'react';
import { Row, Col } from 'antd';
// import logo from '../../../static/img/auth/'

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row>
        <Col xxl={24} xl={24} lg={24} md={24} xs={24}>
          <img src={require('../../../static/img/logo.svg')} alt="" style={{ height: 30, marginLeft: 50, marginTop: 15 }} />
        </Col>
        <Col xxl={24} xl={24} lg={24} md={24} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;