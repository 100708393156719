const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  UPDATE_PROFILE_BEGIN: 'UPDATE_PROFILE_BEGIN',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_ERR: 'UPDATE_PROFILE_ERR',

  FORGOT_PASSWORD_BEGIN: 'FORGOT_PASSWORD_BEGIN',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERR: 'FORGOT_PASSWORD_ERR',

  UPDATE_PASSWORD_BEGIN: 'UPDATE_PASSWORD_BEGIN',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_ERR: 'UPDATE_PASSWORD_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: data => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  loginErr: err => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  forgotPasswordBegin: () => {
    return {
      type: actions.FORGOT_PASSWORD_BEGIN,
    };
  },

  forgotPasswordSuccess: data => {
    return {
      type: actions.FORGOT_PASSWORD_SUCCESS,
      data,
    };
  },

  forgotPasswordErr: err => {
    return {
      type: actions.FORGOT_PASSWORD_ERR,
      err,
    };
  },

  updateProfileBegin: () => {
    return {
      type: actions.UPDATE_PROFILE_BEGIN,
    };
  },

  updateProfileSuccess: data => {
    return {
      type: actions.UPDATE_PROFILE_SUCCESS,
      data,
    };
  },

  updateProfileErr: err => {
    return {
      type: actions.UPDATE_PROFILE_ERR,
      err,
    };
  },

  updatePasswordBegin: () => {
    return {
      type: actions.UPDATE_PASSWORD_BEGIN,
    };
  },

  updatePasswordSuccess: data => {
    return {
      type: actions.UPDATE_PASSWORD_SUCCESS,
      data,
    };
  },

  updatePasswordErr: err => {
    return {
      type: actions.UPDATE_PASSWORD_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: data => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },

  logoutErr: err => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },
};

export default actions;
