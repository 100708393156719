const actions = {
  GET_MODEL_BEGIN: 'GET_MODEL_BEGIN',
  GET_MODEL_SUCCESS: 'GET_MODEL_SUCCESS',
  GET_MODEL_FAILED: 'GET_MODEL_FAILED',

  GET_MODEL_BY_BRAND_BEGIN: 'GET_MODEL_BY_BRAND_BEGIN',
  GET_MODEL_BY_BRAND_SUCCESS: 'GET_MODEL_BY_BRAND_SUCCESS',
  GET_MODEL_BY_BRAND_FAILED: 'GET_MODEL_BY_BRAND_FAILED',

  ADD_MODEL_BEGIN: 'ADD_MODEL_BEGIN',
  ADD_MODEL_SUCCESS: 'ADD_MODEL_SUCCESS',
  ADD_MODEL_FAILED: 'ADD_MODEL_FAILED',

  UPDATE_MODEL_BEGIN: 'UPDATE_MODEL_BEGIN',
  UPDATE_MODEL_SUCCESS: 'UPDATE_MODEL_SUCCESS',
  UPDATE_MODEL_FAILED: 'UPDATE_MODEL_FAILED',

  DELETE_MODEL_BEGIN: 'DELETE_MODEL_BEGIN',
  DELETE_MODEL_SUCCESS: 'DELETE_MODEL_SUCCESS',
  DELETE_MODEL_FAILED: 'DELETE_MODEL_FAILED',

  getModelBegin: () => {
    return {
      type: actions.GET_MODEL_BEGIN,
    };
  },

  getModelSuccess: data => {
    return {
      type: actions.GET_MODEL_SUCCESS,
      data,
    };
  },

  getModelFailed: err => {
    return {
      type: actions.GET_MODEL_FAILED,
      err,
    };
  },

  getModelByBrandBegin: () => {
    return {
      type: actions.GET_MODEL_BY_BRAND_BEGIN,
    };
  },

  getModelByBrandSuccess: data => {
    return {
      type: actions.GET_MODEL_BY_BRAND_SUCCESS,
      data,
    };
  },

  getModelByBrandFailed: err => {
    return {
      type: actions.GET_MODEL_BY_BRAND_FAILED,
      err,
    };
  },

  addModelBegin: () => {
    return {
      type: actions.ADD_MODEL_BEGIN,
    };
  },

  addModelSuccess: data => {
    return {
      type: actions.ADD_MODEL_SUCCESS,
      data,
    };
  },

  addModelFailed: err => {
    return {
      type: actions.ADD_MODEL_FAILED,
      err,
    };
  },

  updateModelBegin: () => {
    return {
      type: actions.UPDATE_MODEL_BEGIN,
    };
  },

  updateModelSuccess: data => {
    return {
      type: actions.UPDATE_MODEL_SUCCESS,
      data,
    };
  },

  updateModelFailed: err => {
    return {
      type: actions.UPDATE_MODEL_FAILED,
      err,
    };
  },
  deleteModelBegin: () => {
    return {
      type: actions.DELETE_MODEL_BEGIN,
    };
  },

  deleteModelSuccess: data => {
    return {
      type: actions.DELETE_MODEL_SUCCESS,
      data,
    };
  },

  deleteModelFailed: err => {
    return {
      type: actions.DELETE_MODEL_FAILED,
      err,
    };
  },
};

export default actions;
