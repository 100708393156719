import { combineReducers } from 'redux';
import BrandReducer from './brand/reducers';
import ModelReducer from './model/reducers';
import BodyTypeReducer from './bodytype/reducers';
import VehicleReducer from './vehicle/reducers';
import VehicleColorReducer from './vehicle color/reducers';
import CompareVehicleReducer from './compare vehicles/reducers';
import AdReducer from './ad/reducers';
import ContactQueryReducer from './contact query/reducers';
import VariantReducer from './variant/reducers';
import DescriptionReducer from './description/reducers';

import ColorReducer from './color/reducers';
import UserReducer from './users/reducers';
import RoleReducer from './role/reducers';
import BlogCategory from './blogcategory/reducers';
import Blog from './blog/reducers';
import BlogVideo from './blog video/reducers';
import FAQ from './faq/reducers';
import Subscriber from './subscriber/reducers';
import Review from './review/reducers';
import Rating from './rating/reducers';
import ReviewRating from './rating&review/reducer';
import Dashboard from './dashboard/reducers';
import ContactInformation from './contact information/reducers';
import SliderReducer from './slider/reducers';
import CallBackReducer from './callback/reducers';
import PageReducer from './page/reducers';
import headerSearchReducer from './headerSearch/reducers';

import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';

const rootReducers = combineReducers({
  auth: authReducer,
  user: UserReducer,
  role: RoleReducer,
  headerSearch: headerSearchReducer,

  brand: BrandReducer,
  model: ModelReducer,
  variant: VariantReducer,
  bodyType: BodyTypeReducer,
  color: ColorReducer,
  vehicle: VehicleReducer,
  vehicleColor: VehicleColorReducer,
  compareVehicle: CompareVehicleReducer,
  description: DescriptionReducer,

  blogCategory: BlogCategory,
  blog: Blog,
  blogVideo: BlogVideo,
  faq: FAQ,

  ad: AdReducer,
  subscriber: Subscriber,
  review: Review,
  rating: Rating,
  reviewrating: ReviewRating,
  dashboard: Dashboard,
  contactInformation: ContactInformation,
  slider: SliderReducer,
  callback: CallBackReducer,
  contactQuery: ContactQueryReducer,
  page: PageReducer,

  notification: readNotificationReducer,
  ChangeLayoutMode,
});

export default rootReducers;
