const actions = {
  GET_COLOR_BEGIN: 'GET_COLOR_BEGIN',
  GET_COLOR_SUCCESS: 'GET_COLOR_SUCCESS',
  GET_COLOR_FAILED: 'GET_COLOR_FAILED',

  ADD_COLOR_BEGIN: 'ADD_COLOR_BEGIN',
  ADD_COLOR_SUCCESS: 'ADD_COLOR_SUCCESS',
  ADD_COLOR_FAILED: 'ADD_COLOR_FAILED',

  UPDATE_COLOR_BEGIN: 'UPDATE_COLOR_BEGIN',
  UPDATE_COLOR_SUCCESS: 'UPDATE_COLOR_SUCCESS',
  UPDATE_COLOR_FAILED: 'UPDATE_COLOR_FAILED',

  DELETE_COLOR_BEGIN: 'DELETE_COLOR_BEGIN',
  DELETE_COLOR_SUCCESS: 'DELETE_COLOR_SUCCESS',
  DELETE_COLOR_FAILED: 'DELETE_COLOR_FAILED',

  getColorBegin: () => {
    return {
      type: actions.GET_COLOR_BEGIN,
    };
  },

  getColorSuccess: data => {
    return {
      type: actions.GET_COLOR_SUCCESS,
      data,
    };
  },

  getColorFailed: err => {
    return {
      type: actions.GET_COLOR_FAILED,
      err,
    };
  },

  addColorBegin: () => {
    return {
      type: actions.ADD_COLOR_BEGIN,
    };
  },

  addColorSuccess: data => {
    return {
      type: actions.ADD_COLOR_SUCCESS,
      data,
    };
  },

  addColorFailed: err => {
    return {
      type: actions.ADD_COLOR_FAILED,
      err,
    };
  },
  updateColorBegin: () => {
    return {
      type: actions.UPDATE_COLOR_BEGIN,
    };
  },

  updateColorSuccess: data => {
    return {
      type: actions.UPDATE_COLOR_SUCCESS,
      data,
    };
  },

  updateColorFailed: err => {
    return {
      type: actions.UPDATE_COLOR_FAILED,
      err,
    };
  },
  deleteColorBegin: () => {
    return {
      type: actions.DELETE_COLOR_BEGIN,
    };
  },

  deleteColorSuccess: data => {
    return {
      type: actions.DELETE_COLOR_SUCCESS,
      data,
    };
  },

  deleteColorFailed: err => {
    return {
      type: actions.DELETE_COLOR_FAILED,
      err,
    };
  },
};

export default actions;
