const actions = {
  GET_REVIEWRATING_BEGIN: 'GET_REVIEWRATING_BEGIN',
  GET_REVIEWRATING_SUCCESS: 'GET_REVIEWRATING_SUCCESS',
  GET_REVIEWRATING_FAILED: 'GET_REVIEWRATING_FAILED',
  ADD_REVIEWRATING_BEGIN: 'ADD_REVIEWRATING_BEGIN',
  ADD_REVIEWRATING_SUCCESS: 'ADD_REVIEWRATING_SUCCESS',
  ADD_REVIEWRATING_FAILED: 'ADD_REVIEWRATING_FAILED',
  DELETE_REVIEWRATING_BEGIN: 'DELETE_REVIEWRATING_BEGIN',
  DELETE_REVIEWRATING_SUCCESS: 'DELETE_REVIEWRATING_SUCCESS',
  DELETE_REVIEWRATING_FAILED: 'DELETE_REVIEWRATING_FAILED',
  UPDATE_REVIEWRATING_BEGIN: 'UPDATE_REVIEWRATING_BEGIN',
  UPDATE_REVIEWRATING_SUCCESS: 'UPDATE_REVIEWRATING_SUCCESS',
  UPDATE_REVIEWRATING_FAILED: 'UPDATE_REVIEWRATING_FAILED',

  getReviewRatingBegin: () => {
    return {
      type: actions.GET_REVIEWRATING_BEGIN,
    };
  },

  getReviewRatingSuccess: data => {
    return {
      type: actions.GET_REVIEWRATING_SUCCESS,
      data,
    };
  },

  getReviewRatingFailed: err => {
    return {
      type: actions.GET_REVIEWRATING_FAILED,
      err,
    };
  },
  addReviewRatingBegin: () => {
    return {
      type: actions.ADD_REVIEWRATING_BEGIN,
    };
  },

  addReviewRatingSuccess: data => {
    return {
      type: actions.ADD_REVIEWRATING_SUCCESS,
      data,
    };
  },

  addReviewRatingFailed: err => {
    return {
      type: actions.ADD_REVIEWRATING_FAILED,
      err,
    };
  },
  deleteReviewRatingBegin: () => {
    return {
      type: actions.DELETE_REVIEWRATING_BEGIN,
    };
  },

  deleteReviewRatingSuccess: data => {
    return {
      type: actions.DELETE_REVIEWRATING_SUCCESS,
      data,
    };
  },

  deleteReviewRatingFailed: err => {
    return {
      type: actions.DELETE_REVIEWRATING_FAILED,
      err,
    };
  },
  updateReviewRatingBegin: () => {
    return {
      type: actions.UPDATE_REVIEWRATING_BEGIN,
    };
  },

  updateReviewRatingSuccess: data => {
    return {
      type: actions.UPDATE_REVIEWRATING_SUCCESS,
      data,
    };
  },

  updateReviewRatingFailed: err => {
    return {
      type: actions.UPDATE_REVIEWRATING_FAILED,
      err,
    };
  },
};

export default actions;
