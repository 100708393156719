const actions = {
  GET_USER_BEGIN: 'GET_USER_BEGIN',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILED: 'GET_USER_FAILED',

  ADD_USER_BEGIN: 'ADD_USER_BEGIN',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAILED: 'ADD_USER_FAILED',

  UPDATE_USER_BEGIN: 'UPDATE_USER_BEGIN',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILED: 'UPDATE_USER_FAILED',

  DELETE_USER_BEGIN: 'DELETE_USER_BEGIN',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILED: 'DELETE_USER_FAILED',

  GET_USER_ONE_BEGIN: 'GET_USER_ONE_BEGIN',
  GET_USER_ONE_SUCCESS: 'GET_USER_ONE_SUCCESS',
  GET_USER_ONE_FAILED: 'GET_USER_ONE_FAILED',

  getUserBegin: () => {
    return {
      type: actions.GET_USER_BEGIN,
    };
  },

  getUserSuccess: data => {
    return {
      type: actions.GET_USER_SUCCESS,
      data,
    };
  },

  getUserFailed: err => {
    return {
      type: actions.GET_USER_FAILED,
      err,
    };
  },

  addUserBegin: () => {
    return {
      type: actions.ADD_USER_BEGIN,
    };
  },

  addUserSuccess: data => {
    return {
      type: actions.ADD_USER_SUCCESS,
      data,
    };
  },

  addUserFailed: err => {
    return {
      type: actions.ADD_USER_FAILED,
      err,
    };
  },

  updateUserBegin: () => {
    return {
      type: actions.UPDATE_USER_BEGIN,
    };
  },

  updateUserSuccess: data => {
    return {
      type: actions.UPDATE_USER_SUCCESS,
      data,
    };
  },

  updateUserFailed: err => {
    return {
      type: actions.UPDATE_USER_FAILED,
      err,
    };
  },

  deleteUserBegin: () => {
    return {
      type: actions.DELETE_USER_BEGIN,
    };
  },

  deleteUserSuccess: data => {
    return {
      type: actions.DELETE_USER_SUCCESS,
      data,
    };
  },

  deleteUserFailed: err => {
    return {
      type: actions.DELETE_USER_FAILED,
      err,
    };
  },
  getUserOneBegin: () => {
    return {
      type: actions.GET_USER_ONE_BEGIN,
    };
  },

  getUserOneSuccess: data => {
    return {
      type: actions.GET_USER_ONE_SUCCESS,
      data,
    };
  },

  getUserOneFailed: err => {
    return {
      type: actions.GET_USER_ONE_FAILED,
      err,
    };
  },
};

export default actions;
