const actions = {
  GET_SLIDER_BEGIN: 'GET_SLIDER_BEGIN',
  GET_SLIDER_SUCCESS: 'GET_SLIDER_SUCCESS',
  GET_SLIDER_FAILED: 'GET_SLIDER_FAILED',

  ADD_SLIDER_BEGIN: 'ADD_SLIDER_BEGIN',
  ADD_SLIDER_SUCCESS: 'ADD_SLIDER_SUCCESS',
  ADD_SLIDER_FAILED: 'ADD_SLIDER_FAILED',

  UPDATE_SLIDER_BEGIN: 'UPDATE_SLIDER_BEGIN',
  UPDATE_SLIDER_SUCCESS: 'UPDATE_SLIDER_SUCCESS',
  UPDATE_SLIDER_FAILED: 'UPDATE_SLIDER_FAILED',

  DELETE_SLIDER_BEGIN: 'DELETE_SLIDER_BEGIN',
  DELETE_SLIDER_SUCCESS: 'DELETE_SLIDER_SUCCESS',
  DELETE_SLIDER_FAILED: 'DELETE_SLIDER_FAILED',

  getSliderBegin: () => {
    return {
      type: actions.GET_SLIDER_BEGIN,
    };
  },

  getSliderSuccess: data => {
    return {
      type: actions.GET_SLIDER_SUCCESS,
      data,
    };
  },

  getSliderFailed: err => {
    return {
      type: actions.GET_SLIDER_FAILED,
      err,
    };
  },

  addSliderBegin: () => {
    return {
      type: actions.ADD_SLIDER_BEGIN,
    };
  },

  addSliderSuccess: data => {
    return {
      type: actions.ADD_SLIDER_SUCCESS,
      data,
    };
  },

  addSliderFailed: err => {
    return {
      type: actions.ADD_SLIDER_FAILED,
      err,
    };
  },
  updateSliderBegin: () => {
    return {
      type: actions.UPDATE_SLIDER_BEGIN,
    };
  },

  updateSliderSuccess: data => {
    return {
      type: actions.UPDATE_SLIDER_SUCCESS,
      data,
    };
  },

  updateSliderFailed: err => {
    return {
      type: actions.UPDATE_SLIDER_FAILED,
      err,
    };
  },
  deleteSliderBegin: () => {
    return {
      type: actions.DELETE_SLIDER_BEGIN,
    };
  },

  deleteSliderSuccess: data => {
    return {
      type: actions.DELETE_SLIDER_SUCCESS,
      data,
    };
  },

  deleteSliderFailed: err => {
    return {
      type: actions.DELETE_SLIDER_FAILED,
      err,
    };
  },
};

export default actions;