const actions = {
  GET_VEHICLE_COLOR_BEGIN: 'GET_VEHICLE_COLOR_BEGIN',
  GET_VEHICLE_COLOR_SUCCESS: 'GET_VEHICLE_COLOR_SUCCESS',
  GET_VEHICLE_COLOR_FAILED: 'GET_VEHICLE_COLOR_FAILED',

  ADD_VEHICLE_COLOR_BEGIN: 'ADD_VEHICLE_COLOR_BEGIN',
  ADD_VEHICLE_COLOR_SUCCESS: 'ADD_VEHICLE_COLOR_SUCCESS',
  ADD_VEHICLE_COLOR_FAILED: 'ADD_VEHICLE_COLOR_FAILED',

  UPDATE_VEHICLE_COLOR_BEGIN: 'UPDATE_VEHICLE_COLOR_BEGIN',
  UPDATE_VEHICLE_COLOR_SUCCESS: 'UPDATE_VEHICLE_COLOR_SUCCESS',
  UPDATE_VEHICLE_COLOR_FAILED: 'UPDATE_VEHICLE_COLOR_FAILED',

  DELETE_VEHICLE_COLOR_BEGIN: 'DELETE_VEHICLE_COLOR_BEGIN',
  DELETE_VEHICLE_COLOR_SUCCESS: 'DELETE_VEHICLE_COLOR_SUCCESS',
  DELETE_VEHICLE_COLOR_FAILED: 'DELETE_VEHICLE_COLOR_FAILED',

  getVehicleColorBegin: () => {
    return {
      type: actions.GET_VEHICLE_COLOR_BEGIN,
    };
  },

  getVehicleColorSuccess: data => {
    return {
      type: actions.GET_VEHICLE_COLOR_SUCCESS,
      data,
    };
  },

  getVehicleColorFailed: err => {
    return {
      type: actions.GET_VEHICLE_COLOR_FAILED,
      err,
    };
  },

  addVehicleColorBegin: () => {
    return {
      type: actions.ADD_VEHICLE_COLOR_BEGIN,
    };
  },

  addVehicleColorSuccess: data => {
    return {
      type: actions.ADD_VEHICLE_COLOR_SUCCESS,
      data,
    };
  },

  addVehicleColorFailed: err => {
    return {
      type: actions.ADD_VEHICLE_COLOR_FAILED,
      err,
    };
  },
  updateVehicleColorBegin: () => {
    return {
      type: actions.UPDATE_VEHICLE_COLOR_BEGIN,
    };
  },

  updateVehicleColorSuccess: data => {
    return {
      type: actions.UPDATE_VEHICLE_COLOR_SUCCESS,
      data,
    };
  },

  updateVehicleColorFailed: err => {
    return {
      type: actions.UPDATE_VEHICLE_COLOR_FAILED,
      err,
    };
  },
  deleteVehicleColorBegin: () => {
    return {
      type: actions.DELETE_VEHICLE_COLOR_BEGIN,
    };
  },

  deleteVehicleColorSuccess: data => {
    return {
      type: actions.DELETE_VEHICLE_COLOR_SUCCESS,
      data,
    };
  },

  deleteVehicleColorFailed: err => {
    return {
      type: actions.DELETE_VEHICLE_COLOR_FAILED,
      err,
    };
  },
};

export default actions;